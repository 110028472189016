import React from 'react'
import { Row, Col } from "antd";
import fulCases1 from "@/assets/img/home-page/success-fulCases1.png";
import fulCases2 from "@/assets/img/home-page/success-fulCases2.png";
import fulCases3 from "@/assets/img/home-page/success-fulCases3.png";
import moegoLogo from "@/assets/img/home-page/moego-logo.png";
import cheeseLogo from "@/assets/img/home-page/cheese-logo.png";
import harmayLogo from "@/assets/img/home-page/harmay-logo.png";
import cheeseBg from "@/assets/img/home-page/cheese-background.png";
import harmayBg from "@/assets/img/home-page/harmay-background.png";
import MoegoBg from "@/assets/img/home-page/Moego-background.png";
import China from "@/assets/img/home-page/China.png";
import Australia from "@/assets/img/home-page/australia.png";
import cifUs from "@/assets/img/home-page/cif_us.png";
import { isMobile } from "@/utils/utils"
import "./index.less";
import { useTranslation } from 'react-i18next';

const SuccessfulCases = (props) => {
	const { t, i18n } = useTranslation();
  return (
    <div className="success-fulCases" style={{ height: isMobile() ? 'auto' : 560}}>
			<div className="case-list">
				<div className="case-li case-li1" style={{ backgroundImage: `url(${MoegoBg})` }}>
					<div className="case-li-content">
						{/* <div className="case-logo-warp">
							<img  className="case-logo-warp" alt="" src={moegoLogo} />
						</div> */}
						<img className="case-logo-warp" alt="" src={moegoLogo} />
						<div className="case-li-title">
							<div className="case-sub-title">MoeGo</div>
							<div className="case-intro flex-center">
								<img className="case-country" alt="" src={cifUs} />
								<img className="case-country" alt="" src={Australia} />
								{t('宠物服务SaaS平台')}
							</div>
						</div>
						<div className="picWarp picWarp1">
							<img className="picWarp-pic picWarp-pic1" src={fulCases1} alt="" />
							{/* <div className="picWarp-pie" /> */}
						</div>
						<div className="case-li-txt">
						{t('美华柏乐采用SpringBoot框架为MoeGo重构了整套后台，使用React Native跨平台技术开发了iOS及Android APP。')}
						</div>
					</div>
				</div>
				<div className="case-li case-li2" style={{ backgroundImage: `url(${harmayBg})` }}>
					<div className="case-li-content">
						{/* <div className="case-logo-warp">
							<div className="">MoeGo</div>
						</div> */}
						<img className="case-logo-warp" alt="" src={harmayLogo} />
						<div className="case-li-title">
							<div className="case-sub-title">Harmay話梅</div>
							<div className="case-intro flex-center">
								<img className="case-country" alt="" src={China} />
								{t('新零售美妆')}
							</div>
						</div>
						<div className="picWarp picWarp2">
							<img className="picWarp-pic picWarp-pic2" src={fulCases2} alt="" />
							{/* <div className="picWarp-pie" /> */}
						</div>
						<div className="case-li-txt">{t('美华柏乐为HARMAY定制开发了专属直播电商微信小程序以及直播后台管理系统。')}</div>
					</div>
				</div>
				<div className="case-li case-li3" style={{ backgroundImage: `url(${cheeseBg})` }}>
					<div className="case-li-content">
						<img className="case-logo-warp" alt="" src={cheeseLogo} />
						<div className="case-li-title">
							<div className="case-sub-title">Cheese Card</div>
							<div className="case-intro flex-center">
								<img className="case-country" alt="" src={cifUs} />
								{t('金融科技')}
							</div>
						</div>
						<div className="picWarp picWar3">
							<img className="picWarp-pic picWarp-pic3" src={fulCases3} alt="" />
							{/* <div className="picWarp-pie" /> */}
						</div>
						<div className="case-li-txt">{t('美华柏乐为Cheese Card开发了定制化iOS APP以及整套后台支付交易系统。')}</div>
					</div>
				</div>
			</div>
		</div>
  )
}

export default SuccessfulCases;
