import React, { Component, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./header.less";
import logo from "@/assets/icon/logo.png";
import iconMenu from "@/assets/icon/icon-menu.png";
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import {getParameters} from '@/utils/utils';

function Header() {
  const { t, i18n } = useTranslation();
	const {lang} = getParameters();
  const navList = [
    {
      title: t('首页'),
      path: "/home",
    },
    // {
    //   title: "在线咨询",
    //   path: "/test",
    // },
    {
      title: t('成功案例'),
      path: "/case",
    },
    {
      title: t('关于我们'),
      path: "/about",
    },
  ];
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [locale, setLocale] = useState('zh')
  useEffect(()=>{
    let lsLocal = localStorage.getItem('blur-website-locale');
    if (lsLocal) {
      i18n.changeLanguage(lsLocal)
      setLocale(lsLocal)
    } else {
      const validLang = ['zh', 'en'].includes(lang) ? lang : 'en'
      i18n.changeLanguage(validLang)
      setLocale(validLang)
      localStorage.setItem('blur-website-locale', validLang);
    }
  },[])
  const onLocaleChange = value => {
    i18n.changeLanguage(value)
    setLocale(value)
    localStorage.setItem('blur-website-locale', value);
  }
  return (
    <>
      <div className="site-header-nav">
        <img className="site-header-nav-logo" src={logo}  alt="logo"/>
        <div className="site-header-nav-btn-group">
          {navList.map((item) => (
            <NavLink
            key={item.path}
              to={item.path}
              activeClassName="nav-active"
              className="site-header-nav-btn-group-item"
            >
              {item.title}
            </NavLink>
          ))}
          <Radio.Group value={locale} onChange={e=>onLocaleChange(e.target.value)}>
            <Radio.Button key="en" value="en">
              English
            </Radio.Button>
            <Radio.Button key="zh" value="zh">
              中文
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div
      className={[
            "site-header-mobile-nav",
            showMobileNav ? "show-nav" : null,
          ].join(" ")}>
        <div className="site-header-mobile-nav-header">
          <img className="site-header-mobile-nav-header-logo" src={logo}  alt="logo"/>
          <Radio.Group style={{marginLeft: '20px'}} value={locale} onChange={e=>onLocaleChange(e.target.value)}>
            <Radio.Button key="en" value="en">
              English
            </Radio.Button>
            <Radio.Button key="zh" value="zh">
              中文
            </Radio.Button>
          </Radio.Group>
          <img onClick={()=>{setShowMobileNav(!showMobileNav)}} 
           alt="menu"
          className={[
            "site-header-mobile-nav-header-icon",
            showMobileNav ? "show-nav" : null,
          ].join(" ")}
          src={iconMenu} />
        </div>
        
        <div
          className="site-header-mobile-nav-btn-group"
        >
          {navList.map((item) => (
            <NavLink
            key={item.path}
              to={item.path}
              activeClassName="nav-active"
              className="site-header-mobile-nav-btn-group-item"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
}

export default Header;
