import React, { Component, Fragment, useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import Typed from "typed.js";
import anime from "animejs/lib/anime.es.js";
import {
  UpOutlined
} from '@ant-design/icons';
import "./index.less";
import { homepageDatas } from '../Case/CaseDetail/data'
import Footer from "@/components/Footer/index.js";
import {getParameters} from '@/utils/utils';

import radpadLeft from "@/assets/img/home-page/radpad-left.png";
import radpadRight from "@/assets/img/home-page/radpad-right.png";
import radpadCenter from "@/assets/img/home-page/radpad-center.png";
import stripeLeft from "@/assets/img/home-page/stripe-left.png";
import stripeRight from "@/assets/img/home-page/stripe-right.png";
import homeConsult from "@/assets/img/home-page/home-consult.svg";
import homeDevelopment from "@/assets/img/home-page/home-development.png";
import homeMap from "@/assets/img/home-page/home-map.svg";
import homeMapMobile from "@/assets/img/home-page/home-map-mobile.svg";
import homeSV from "@/assets/img/home-page/home-sv.svg";
import homeLA from "@/assets/img/home-page/home-la.svg";
import homeBeijing from "@/assets/img/home-page/home-beijing.svg";

import blurLogoWhite from "@/assets/icon/logo-white.png";
import iconUs from "@/assets/icon/icon-us.png";
import iconCheese from "@/assets/icon/icon-cheese.png";
import iconCosmopolitan from "@/assets/icon/icon-cosmopolitan.png";
import iconHarmay from "@/assets/icon/icon-harmay.png";
import iconKEmsstudio from "@/assets/icon/icon-k-emsstudio.png";
import iconPriority from "@/assets/icon/icon-priority.png";
import iconPrismpop from "@/assets/icon/icon-prismpop.png";
import iconRadpad from "@/assets/icon/icon-radpad.png";
import iconShopshops from "@/assets/icon/icon-shopshops.png";
import iconTrashaus from "@/assets/icon/icon-trashaus.png";
import iconTripalink from "@/assets/icon/icon-tripalink.png";

import iconInfibraintech from "@/assets/icon/icon-infibraintech.png";
import iconMoego from "@/assets/icon/icon-moego.png";
import iconOuter from "@/assets/icon/icon-outer.png";
import iconRabbit from "@/assets/icon/icon-rabbit.png";
import iconLanxiang from "@/assets/icon/icon-lanxiang.png";

import BusinessProcess from './component/BusinessProcess';
import ProjectCases from './component/ProjectCases';
import SuccessfulCases from './component/SuccessfulCases';
import TechnologyStack from './component/TechnologyStack';
import CoreTeam from './component/CoreTeam';
import { isMobile, isZh, openMeiqia } from "@/utils/utils"
import ButtonArrow from "@/components/ButtonArrow/index.js";
import Expanse from "react-expanse"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Home() {
  const history = useHistory()
  const { t, i18n } = useTranslation();
  const [showTechnology, setShowTechnology] = useState(false);
  const [showCoreTeam, setShowCoreTeam] = useState(false);
	const {lang} = getParameters();
  const clientsList = [
    {
      logo: iconRadpad,
    },
    {
      logo: iconCosmopolitan,
    },
    {
      logo: iconHarmay,
    },
    {
      logo: iconTrashaus,
    },
    {
      logo: iconMoego,
    },
    {
      logo: iconCheese,
    },
    {
      logo: iconKEmsstudio,
    },
    {
      logo: iconPrismpop,
    },
    {
      logo: iconTripalink,
    },
    {
      logo: iconPriority,
    },
    {
      logo: iconRabbit,
    },
    {
      logo: iconOuter,
    },
    {
      logo: iconInfibraintech,
    },
    {
      logo: iconLanxiang
    },
    {
      logo: iconShopshops,
    },
  ];
  useEffect(() => {
    let lsLocal = localStorage.getItem('blur-website-locale');
    if (lsLocal) {
      i18n.changeLanguage(lsLocal)
    } else {
      const validLang = ['zh', 'en'].includes(lang) ? lang : 'en'
      i18n.changeLanguage(validLang)
      localStorage.setItem('blur-website-locale', validLang);
    }
    new Typed("#blurtyped", {
      strings: [
        "private setListener = () => {\nthis.unsubscribe = this.props.navigation.addListener('focus', () => {\nthis.refreshAppointmentsPageData();",
        "if (this.dateSelectorRef != null) this.dateSelectorRef.hideCalendar();\nconst { viewMode } = this.state;",
        "if (viewMode === EAppointmentsViewMode.MAP && this.agendaMapViewRef != null) { \nthis.agendaMapViewRef.collapseExpandedCard();\n}\n });",
        "start with blur!",
      ],
      typeSpeed: 50,
      startDelay: 300,
      loop: true,
    })

    function fitElementToParent(el, padding) {
      var timeout = null;
      function resize() {
        if (timeout) clearTimeout(timeout);
        anime.set(el, { scale: 1 });
        var pad = padding || 0;
        var parentEl = el.parentNode;
        var elOffsetWidth = el.offsetWidth - pad;
        var parentOffsetWidth = parentEl.offsetWidth;
        var ratio = parentOffsetWidth / elOffsetWidth;
        timeout = setTimeout(anime.set(el, { scale: ratio }), 10);
      }
      resize();
      window.addEventListener("resize", resize);
    }

    var advancedStaggeringAnimation = (function () {
      var staggerVisualizerEl = document.querySelector(".stagger-visualizer");
      var dotsWrapperEl = staggerVisualizerEl.querySelector(".dots-wrapper");
      var dotsFragment = document.createDocumentFragment();
      var grid = [20, 10];
      var cell = 55;
      var numberOfElements = grid[0] * grid[1];
      var animation;
      var paused = true;

      fitElementToParent(staggerVisualizerEl, 0);

      for (var i = 0; i < numberOfElements; i++) {
        var dotEl = document.createElement("div");
        dotEl.classList.add("dot");
        dotsFragment.appendChild(dotEl);
      }

      dotsWrapperEl.appendChild(dotsFragment);

      var index = anime.random(0, numberOfElements - 1);
      var nextIndex = 0;

      function play() {
        paused = false;
        if (animation) animation.pause();

        nextIndex = anime.random(0, numberOfElements - 1);

        animation = anime
          .timeline({
            easing: "easeInOutQuad",
            complete: play,
          })
          .add(
            {
              targets: ".stagger-visualizer .dot",
              keyframes: [
                {
                  translateX: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  duration: 100,
                },
                {
                  translateX: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  scale: anime.stagger([2.6, 1], { grid: grid, from: index }),
                  duration: 225,
                },
                {
                  translateX: 0,
                  translateY: 0,
                  scale: 1,
                  duration: 1200,
                },
              ],
              delay: anime.stagger(80, { grid: grid, from: index }),
            },
            30
          )
        index = nextIndex;
      }
      play()
    })()

    var advancedMobileStaggeringAnimation = (function () {
      var staggerVisualizerEl = document.querySelector(".stagger-visualizer-mobile");
      var dotsWrapperEl = staggerVisualizerEl.querySelector(".dots-wrapper");
      var dotsFragment = document.createDocumentFragment();
      var grid = [6, 10];
      var cell = 55;
      var numberOfElements = grid[0] * grid[1];
      var animation;
      var paused = true;

      fitElementToParent(staggerVisualizerEl, 0);

      for (var i = 0; i < numberOfElements; i++) {
        var dotEl = document.createElement("div");
        dotEl.classList.add("dot");
        dotsFragment.appendChild(dotEl);
      }

      dotsWrapperEl.appendChild(dotsFragment);

      var index = anime.random(0, numberOfElements - 1);
      var nextIndex = 0;

      function play() {
        paused = false;
        if (animation) animation.pause();

        nextIndex = anime.random(0, numberOfElements - 1);

        animation = anime
          .timeline({
            easing: "easeInOutQuad",
            complete: play,
          })
          .add(
            {
              targets: ".stagger-visualizer-mobile .dot",
              keyframes: [
                {
                  translateX: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  duration: 100,
                },
                {
                  translateX: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  scale: anime.stagger([2.6, 1], { grid: grid, from: index }),
                  duration: 225,
                },
                {
                  translateX: 0,
                  translateY: 0,
                  scale: 1,
                  duration: 2500,
                },
              ],
              delay: anime.stagger(80, { grid: grid, from: index }),
            },
            30
          )
        index = nextIndex;
      }
      play()
    })()
  }, []);
  return (
    <Fragment>
      <div className="home-page">
        <section>
          <div className="home-page-header">
            <div className="home-page-header-bg">
              <div className="stagger-visualizer">
                <div className="dots-wrapper"></div>
              </div>
            </div>
            <div className="home-page-header-bgmobile">
              <div className="stagger-visualizer-mobile">
                <div className="dots-wrapper"></div>
              </div>
            </div>
            <div className="home-page-header-text">
              <h4>{t('为创业而生')}  {isZh() === true ? "-" : ": "} {t('重新定义软件开发')}</h4>
              <h1>
                <strong>{t('阿布扎比、洛杉矶、北京')}</strong>
              </h1>
              <h1>
              {t('专业')} {isZh() === true ? null : <br/>}<strong>{t('软件技术研发')}</strong><br />
                {t('一站式IT服务供应商')}
              </h1>
            </div>
            <div className="home-page-header-consult-btn">
              <ButtonArrow
                btnArrow = {{
                  style: {background: '#C9394C', alignSelf: 'flex-start', marginTop: isZh() === true ? '13px' : '30px'},
                  onClick: () => {
                    openMeiqia()
                  }
                }}
                isAnimation={false}
                title={t('在线咨询')}
              />
            </div>
          </div>
        </section>
        <section className="home-page-advantage">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-advantage-left">
                <div className="home-page-advantage-left-subtitle">
                {isZh() === true ? (<span>核心竞争力 </span> ) : null} INNOVATIVE SOLUTIONS
                </div>
                <div className="home-page-advantage-left-title">
                {t('中美跨境协同开发')}
                </div>
                <div className="home-page-advantage-left-text">
                  {t('我们为全球企业提供全程技术服务——咨询、设计、开发、测试、运营、维护，协助企业进行定制化互联网产品的设计与研发，为企业注入互联网+大数据基因，实现运营效率及盈利能力的跨越式提升。')}
                </div>
                <div onClick={() => setShowTechnology(!showTechnology)} className="home-page-advantage-left-detail-btn">
                  <ButtonArrow
                    btnArrow = {{
                      style: {alignSelf: 'flex-start'},
                      onClick: () => { }
                    }}
                    type="lower"
                    isAnimation={false}
                    title={t('查看我们的技术栈')}
                  />
                </div>
              </div>
            </Col>
            <Col xs={3} sm={1}></Col>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={12}>
              <div className="text-editor-wrap">
                <div className="title-bar">
                  <span className="title">blur.js - bash 80x24</span>
                </div>
                <div className="text-body">
                  <span id="blurtyped"></span>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="technology-section">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <TechnologyStack setShowTechnology={setShowTechnology} showTechnology={showTechnology} />
            </Col>
            <Col xs={3} sm={3}></Col>
          </Row>
        </section>
        <section className="home-page-development">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11} style={{display: 'flex', alignItems: 'center'}}>
              <div className="home-page-development-left">
                <div className="home-page-development-left-subtitle">
                {isZh() === true ? (<span>项目开发 </span> ) : null}TECHNICAL DEVELOPMENT
                </div>
                <div className="home-page-development-left-title">{t('业务流程')}</div>
                <div className="home-page-development-left-text">
                  {t('我们利用多年来在美国硅谷积累的专业化IT服务经验，')}
                  <br />
                  {t('发挥中美跨境研发与资源整合优势，')}
                  <br />
                  {t('助力企业进行定制化、全周期、一站式的互联网产品设计与研发。')}
                </div>
              </div>
            </Col>
            <Col xs={0} sm={4}></Col>
            <Col xs={18} sm={9}>
              <div className="home-page-development-right">
                <div className="home-page-development-right-img">
                  <img alt="development" src={homeDevelopment} style={{width: '100%'}}/>
                </div>
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <section>
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <BusinessProcess />
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
      
        <img
          alt="stripe"
          className="home-page-stripe-right"
          src={stripeRight}
        />
        <section className="home-page-radpad">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-radpad-left">
                <div className="home-page-radpad-left-subtitle">
                {t('移动租房一体化平台RadPad')}
                </div>
                <div className="home-page-radpad-left-title">
                {t('APP STORE年度最佳APP')}
                </div>
                <div className="home-page-radpad-left-describe">
                  <img src={iconUs} alt="US" />
                  {t('房地产金融科技')}
                </div>
                <div className="home-page-radpad-left-text">
                {t('RadPad是洛杉矶第一、全美第二大的互联网租房平台，旨在为租客提供找房租房、信用报告、电子合同、移动支付、房屋保险等服务的一站式解决方案。')}
                  <br />
                  <br />
                  {t('RadPad曾多次被App Store评为年度最佳应用及最佳生活应用，其安卓版本也多次获得谷歌Play Store官方推荐。自RadPad上线以来，共取得了12000万美元的融资，于2018年被纳斯达克上市公司Priority(Nasdaq：PRTH)收购。')}
                </div>
                <div className="home-page-radpad-left-detail-btn">
                  <ButtonArrow
                    btnArrow = {{
                      style: {marginTop: 20, alignSelf: 'flex-start'},
                      onClick: () => { history.push(`/case/radpad`) }
                    }}
                    isAnimation={false}
                    title={t('查看详情')}
                  />
                </div>
              </div>
            </Col>
            <Col xs={0} sm={2}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-radpad-right">
                <div className="home-page-radpad-right-img">
                  <img
                    alt="redpad-left"
                    className="home-page-radpad-right-img-left"
                    src={radpadLeft}
                  />
                  <img
                    alt="redpad-center"
                    className="home-page-radpad-right-img-center"
                    src={radpadCenter}
                  />
                  <img
                    alt="redpad-right"
                    className="home-page-radpad-right-img-right"
                    src={radpadRight}
                  />
                </div>
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
            <Col xs={24} sm={24}>
              <div className="home-page-radpad-mobile">
                <div className="home-page-radpad-mobile-text">
                {t('RadPad是洛杉矶第一、全美第二大的互联网租房平台，旨在为租客提供找房租房、信用报告、电子合同、移动支付、房屋保险等服务的一站式解决方案。')}
                </div>
                <div className="home-page-radpad-mobile-detail-btn">
                  <ButtonArrow
                    btnArrow = {{
                      style: {marginTop: 20, alignSelf: 'flex-start'},
                      onClick: () => { history.push(`/case/radpad`) }
                    }}
                    isAnimation={false}
                    title={t('查看详情')}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="home-page-case">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <SuccessfulCases />
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
          {/* <SuccessfulCases /> */}
          <div className="home-page-case-more-btn">
            <ButtonArrow
              btnArrow = {{
                style: {background: '#C9394C', alignSelf: 'flex-start'},
                onClick: () => { history.push(`/case`) }
              }}
              isAnimation={false}
              title={t('查看更多成功案例')}
            />
          </div>
          
        </section>
   
      
      <img alt="stripe" className="home-page-stripe-left" src={stripeLeft} />
        <section>
          <Row>
            <Col xs={24} sm={24}>
              <ProjectCases caseDatas={homepageDatas}/>
            </Col>
          </Row>
        </section>
        <section className="home-page-aboutus">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-aboutus-left">
                <div className="home-page-aboutus-left-subtitle">
                  {isZh() === true ? (<span>关于美华柏乐 </span> ) : null}ABOUT US
                </div>
                <div className="home-page-aboutus-left-title">{t('我们的团队')}</div>
                <div className="home-page-aboutus-left-text">
                {t('北京美华柏乐科技有限公司是一家具有全球视野、从事软件服务外包业务的高新技术企业。我们为中美企业提供咨询、设计、开发、测试、运营及维护的全生命周期技术服务。公司总部位于美国洛杉矶，拥有数名来自Airbnb、Facebook、Google、Snapchat等美国顶级互联网公司的高级研发工程师。我们提供的技术服务已经帮助客户成功获得总额超过5亿元的融资，其中8家公司完成A轮融资，3家公司完成B轮融资，1家公司被收购。')}
                </div>
                <div className="home-page-aboutus-left-more-btn" onClick={() => setShowCoreTeam(!showCoreTeam)}>
                  <ButtonArrow
                    btnArrow = {{
                      style: {alignSelf: 'flex-start'},
                      onClick: () => { }
                    }}
                    type="lower"
                    isAnimation={false}
                    title={t('查看核心团队')}
                  />
                </div>
              </div>
            </Col>
            <Col xs={3} sm={5}></Col>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={8}>
              <div className="home-page-aboutus-right">
                <Row>
                  <Col xs={8} sm={24}>
                    <div className="home-page-aboutus-right-count">10+</div>
                    <div className="home-page-aboutus-right-title">
                    {t('行业经验')}
                    </div>
                  </Col>
                  <Col xs={9} sm={24}>
                    <div className="home-page-aboutus-right-count">20+</div>
                    <div className="home-page-aboutus-right-title">
                    {t('高级研发工程师')}
                    </div>
                  </Col>
                  <Col xs={7} sm={24}>
                    <div className="home-page-aboutus-right-count">50+</div>
                    <div className="home-page-aboutus-right-title">
                    {t('成功案例')}
                    </div>
                  </Col>
                </Row>
                <Row className="home-page-aboutus-right-text">
                  <Col xs={24} sm={0}>
                    <div>
                      北京美华柏乐科技有限公司是一家具有全球视野、从事软件服务外包业务的高新技术企业。我们为中美企业提供咨询、设计、开发、测试、运营及维护的全生命周期技术服务。公司总部位于美国洛杉矶，拥有数名来自Airbnb、Facebook、Google、Snapchat等美国顶级互联网公司的高级研发工程师。我们提供的技术服务已经帮助客户成功获得总额超过5亿元的融资，其中8家公司完成A轮融资，3家公司完成B轮融资，1家公司被收购。
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="home-page-aboutus-right-more-btn" onClick={() => setShowCoreTeam(!showCoreTeam)}>
                <ButtonArrow
                  btnArrow = {{
                    style: {alignSelf: 'flex-start'},
                    onClick: () => { }
                  }}
                  type="lower"
                  isAnimation={false}
                  title='查看核心团队'
                />
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <Expanse show={showCoreTeam}>
          <div className="coreTeam-section">
            <CoreTeam isMobile={isMobile} />
            <div className="pack-up">
              <UpOutlined onClick={() => setShowCoreTeam(false)} />
            </div>
          </div>
        </Expanse>
        <section className="home-page-clients">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <div className="home-page-clients-subtitle">PARTNERS</div>
              <div className="home-page-clients-title">{t('合作客户')}</div>
              <div className="home-page-clients-list">
                {clientsList.map((item) => (
                  <div
                    key={item.logo}
                    xs={12}
                    sm={5}
                    className="home-page-clients-list-item"
                  >
                    <img src={item.logo} alt="logo" />
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <section className="home-page-consult">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-consult-left">
                <div className="home-page-consult-left-title">
                {t('对我们的服务感兴趣？')}
                </div>
                <div className="home-page-consult-left-text">
                  {t('不论您有一个好的创业想法需要技术支持，还是现有软件系统需要更新升级，您的任何技术问题都可以跟我们沟通，让我们为您提供更专业的建议。')}
                </div>
                <div className="home-page-consult-left-consult-btn">
                  <ButtonArrow
                    btnArrow = {{
                      style: {background: '#C9394C', alignSelf: 'flex-start'},
                      onClick: () => {
                        openMeiqia()
                      }
                    }}
                    isAnimation={false}
                    title={t('在线咨询')}
                  />
                </div>
              </div>
            </Col>
            <Col xs={0} sm={4}></Col>
            <Col xs={18} sm={9}>
              <div className="home-page-consult-right">
                <div className="home-page-consult-right-img">
                  <img alt="consult" src={homeConsult} />
                </div>
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <div className="home-page-footer-line"></div>
        <img alt="map" src={homeMap} className="home-page-map" />
        <img
          alt="mobile-map"
          src={homeMapMobile}
          className="home-page-mobile-map"
        />
          <Footer/>
      </div>
    </Fragment>
  );
}

export default Home;
