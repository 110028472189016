import React, { Fragment, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/Home';
import Test from './pages/Test';
import Case from './pages/Case'
import About from './pages/About'
import CaseDetail from './pages/Case/CaseDetail'
function RouteComponent() {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <Fragment>
      <Switch>
        <Route exact path="/home" component={Home}></Route>
        <Route path="/test" component={Test}></Route>
        <Route path="/home" component={Home}></Route>
        <Route path="/case" exact component={Case}></Route>
        <Route path="/case/:dataName" exact component={CaseDetail}></Route>
        <Route path="/about" exact component={About}></Route>
        <Redirect to="/home"></Redirect>
      </Switch>
    </Fragment>
  );
}
export default RouteComponent;