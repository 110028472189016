import React, { Component, Fragment, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import "./index.less";

import homeSV from "@/assets/img/home-page/home-sv.svg";
import homeLA from "@/assets/img/home-page/home-la.svg";
import homeBeijing from "@/assets/img/home-page/home-beijing.svg";
import blurLogoWhite from "@/assets/icon/logo-white.png";
import iconPhone from "@/assets/icon/icon-phone.png";
import iconWechat from "@/assets/icon/icon-email.svg";
import iconEmail from "@/assets/icon/icon-email.png";
import iconPosition from "@/assets/icon/icon-position.png";
import { openMeiqia } from "@/utils/utils"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  return (
    <footer className='footer'>
      <section className="footer-wrap">
        <Row style={{ alignItems: "center" }}>
          <Col xs={0} sm={2}></Col>
          <Col xs={24} sm={6} className="footer-wrap-contacts">
            <div  className="footer-wrap-contacts-logo">
              <img src={blurLogoWhite} alt="blur-logo"/>
            </div>
           
            <div className="footer-wrap-contacts-item footer-wrap-contacts-phone">
              <img src={iconPhone} alt="phone" />
              <div>
                +86 13121106309 (Mainland China)<br />
                +1 (213) 509 3215 (U.S.)<br />
                +971 58 550 6263 (UAE)
              </div>
            </div>
            <div className="footer-wrap-contacts-item">
              <img src={iconWechat} alt="wechat" style={{width: 13}}/>
              +971 58 550 6263
            </div>
            <div className="footer-wrap-contacts-item">
              <img src={iconEmail} alt="email" />
              justin@blur.consulting
            </div>
          </Col>
          <Col xs={0} sm={2}></Col>
          <Col xs={24} sm={3} className="footer-wrap-navs">
            <div onClick={() => {history.push('/case')}}>{t('成功案例')}</div>
            <div onClick={() => {history.push('/about')}}>{t('关于我们')}</div>
            <div onClick={() => {openMeiqia()}}>{t('在线咨询')}</div>
          </Col>
          <Col xs={24} sm={10} className="footer-wrap-addresses">
            <Row className="footer-wrap-addresses-item">
              <Col
                xs={24}
                sm={9}
                className="footer-wrap-addresses-item-logo"
              >
                <img src={homeBeijing} alt="beijing" />
              </Col>
              <Col xs={24} sm={15}>
                <div className="footer-wrap-addresses-item-city">
                  BEIJING
                </div>
                <div className="footer-wrap-addresses-item-content">
                  <img src={iconPosition} alt="position" />
                  <div>
                    北京市海淀区成府路45号
                    <br />
                    中关村智造大街G座四层J611
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="footer-wrap-addresses-item">
              <Col
                xs={24}
                sm={9}
                className="footer-wrap-addresses-item-logo"
              >
                <img src={homeLA} alt="LA" />
              </Col>
              <Col xs={24} sm={15}>
                <div className="footer-wrap-addresses-item-city">
                  LOS ANGELES
                </div>
                <div className="footer-wrap-addresses-item-content">
                  <img src={iconPosition} alt="position" />
                  <div>
                    1639 Camden Ave, 
                    <br />
                    Los Anagels, CA 90025, United States
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="footer-wrap-addresses-item">
              <Col
                xs={24}
                sm={9}
                className="footer-wrap-addresses-item-logo"
              >
                <img src={homeSV} alt="SV" />
              </Col>
              <Col xs={24} sm={15}>
                <div className="footer-wrap-addresses-item-city">
                  ABU DHABI
                </div>
                <div className="footer-wrap-addresses-item-content">
                  <img src={iconPosition} alt="position" />
                  <div>
                    VD - First Floor, Incubator Building,<br />
                    Masdar City, Abu Dhabi, United Arab Emirates
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={1}></Col>
        </Row>
      </section>
      <section className="footer-beian">
        <Row>
          <Col xs={24} sm={12} className="footer-beian-company">
            ©️ Copyright 2016-2024 {t('北京美华柏乐科技有限公司')}{`  `}
          </Col>
          <Col xs={24} sm={12} className="footer-beian-icpnumber">
            {" "}
            All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19007374号</a>
          </Col>
        </Row>
      </section>
    </footer>
  );
}

export default Footer;
