
import iconUs from "@/assets/icon/icon-us.png";
import iconKE from "@/assets/icon/icon-ke.png";
import iconChina from "@/assets/icon/icon-chn.png";
import iconAu from "@/assets/icon/icon-au.svg";
import iconEgy from "@/assets/icon/icon-egy.png";
import iconTC from "@/assets/icon/tc.svg";
import iconMini from "@/assets/icon/mini.svg";
import iconRubyOnRails from "@/assets/icon/Ruby-on-Rails.svg";
import iconReact from "@/assets/icon/React.svg";
import iconPostgreSQL from "@/assets/icon/PostgreSQL.svg";
import iconAWS from "@/assets/icon/AWS.svg";
import iconSwift from "@/assets/icon/Swift.svg";
import iconNode from "@/assets/icon/node.svg";
import iconFlutter from "@/assets/icon/flutter.svg";
import iconAndroid from "@/assets/icon/Android.svg";
import iconGolang from "@/assets/icon/Golang.svg";
import iconMySQL from "@/assets/icon/MySQL.svg";

import wapipayBg from "@/assets/img/case-page/detail/wapipay/backgroud.png";
import wapipayApp from "@/assets/img/case-page/detail/wapipay/headerApp.png";
import wapipayRequired from "@/assets/img/case-page/detail/wapipay/required.png";
import wapipaySolution from "@/assets/img/case-page/detail/wapipay/solution.png";

import swypeBg from "@/assets/img/case-page/detail/swype/backgroud.png";
import swypeApp from "@/assets/img/case-page/detail/swype/headerApp.png";
import swypeRequired from "@/assets/img/case-page/detail/swype/required.png";
import swypeSolution from "@/assets/img/case-page/detail/swype/solution.png";

import radpadBg from "@/assets/img/case-page/detail/radpad/radpad-bg.png";
import radpadMac from "@/assets/img/case-page/detail/radpad/radpad-mac.png";
import radpadRequired from "@/assets/img/case-page/detail/radpad/required.png";
import radpadSolution from "@/assets/img/case-page/detail/radpad/solution.png";

import moegoBg from "@/assets/img/case-page/detail/moego/backgroud.jpg";
import moegoHeaderMac from "@/assets/img/case-page/detail/moego/headerMac.png";
import moegoRequired from "@/assets/img/case-page/detail/moego/required.png";

import harmayBg from "@/assets/img/case-page/detail/harmay/backgroud.png";
import harmayApp from "@/assets/img/case-page/detail/harmay/hedaerApp.png";
import harmayRequired from "@/assets/img/case-page/detail/harmay/required.png";
import harmaySolution from "@/assets/img/case-page/detail/harmay/solution.png";

import cheeseBg from "@/assets/img/case-page/detail/cheese/backgroud.png";
import cheeseApp from "@/assets/img/case-page/detail/cheese/cheeseApp.png";
import cheeseRequired from "@/assets/img/case-page/detail/cheese/required.png";
import cheeseSolution from "@/assets/img/case-page/detail/cheese/solution.png";

import carezBg from "@/assets/img/case-page/detail/carez/backgroud.png";
import carezApp from "@/assets/img/case-page/detail/carez/carezApp.png";
import carezRequired from "@/assets/img/case-page/detail/carez/required.png";
import carezSolution from "@/assets/img/case-page/detail/carez/solution.png";

import qihuibaoBg from "@/assets/img/case-page/detail/qihuibao/backgroud.png";
import qihuibaoApp from "@/assets/img/case-page/detail/qihuibao/qihuibaoApp.png";
import qihuibaoRequired from "@/assets/img/case-page/detail/qihuibao/required.png";
import qihuibaoSolution from "@/assets/img/case-page/detail/qihuibao/solution.png";

import glassesBg from "@/assets/img/case-page/detail/glasses/backgroud.png";
import glassesApp from "@/assets/img/case-page/detail/glasses/glassesApp.png";
import glassesRequired from "@/assets/img/case-page/detail/glasses/required.png";
import glassesSolution from "@/assets/img/case-page/detail/glasses/solution.png";

import kemsBg from "@/assets/img/case-page/detail/kems/backgroud.png";
import kemsApp from "@/assets/img/case-page/detail/kems/kemsApp.png";
import kemsRequired from "@/assets/img/case-page/detail/kems/required.png";
import kemsSolution from "@/assets/img/case-page/detail/kems/solution.png";

import shopshopsBg from "@/assets/img/case-page/detail/shopshops/backgroud.png";
import shopshopsApp from "@/assets/img/case-page/detail/shopshops/shopshopsApp.png";
import shopshopsRequired from "@/assets/img/case-page/detail/shopshops/required.png";
import shopshopsSolution from "@/assets/img/case-page/detail/shopshops/solution.png";

import prismPopBg from "@/assets/img/case-page/detail/prismPop/backgroud.png";
import prismPopApp from "@/assets/img/case-page/detail/prismPop/prismPopApp.png";
import prismPopRequired from "@/assets/img/case-page/detail/prismPop/required.png";
import prismPopSolution from "@/assets/img/case-page/detail/prismPop/solution.png";

import tripalinkBg from "@/assets/img/case-page/detail/tripalink/backgroud.png";
import tripalinkApp from "@/assets/img/case-page/detail/tripalink/tripalinkApp.png";
import tripalinkRequired from "@/assets/img/case-page/detail/tripalink/required.png";
import tripalinkSolution from "@/assets/img/case-page/detail/tripalink/solution.png";

import trashausBg from "@/assets/img/case-page/detail/trashaus/backgroud.png";
import trashausApp from "@/assets/img/case-page/detail/trashaus/trashausApp.png";
import trashausRequired from "@/assets/img/case-page/detail/trashaus/required.png";
import trashausSolution from "@/assets/img/case-page/detail/trashaus/solution.png";

import rabbitBg from "@/assets/img/case-page/detail/rabbit/backgroud.png";
import rabbitApp from "@/assets/img/case-page/detail/rabbit/rabbitApp.png";
import rabbitRequired from "@/assets/img/case-page/detail/rabbit/required.png";
import rabbitSolution from "@/assets/img/case-page/detail/rabbit/solution.png";

import radpadLeft from "@/assets/img/home-page/radpad-left.png";
import radpadCenter from "@/assets/img/home-page/radpad-center.png";
import radpadRight from "@/assets/img/home-page/radpad-right.png";
import moegoIphone from "@/assets/img/case-page/moego-iphone.png";
import moegoMac from "@/assets/img/case-page/moego-mac.png";
import harmayIphone1 from "@/assets/img/case-page/harmay-iphone1.png";
import harmayIphone2 from "@/assets/img/case-page/harmay-iphone2.png";
import cheeseImg1 from "@/assets/img/case-page/cheese-1.png";
import cheeseImg2 from "@/assets/img/case-page/cheese-2.png";
import carezImg from "@/assets/img/case-page/carez.png";
import qhbMacImg from "@/assets/img/case-page/qhb-mac.png";
import qhbTopImg from "@/assets/img/case-page/qhb-1.png";
import qhbBottomImg from "@/assets/img/case-page/qhb-2.png";
import glassesImg from "@/assets/img/case-page/glasses-1.png";
import glassesAppImg from "@/assets/img/case-page/glasses-2.svg";
import kemsLeftImg from "@/assets/img/case-page/kems-left.svg";
import kemsCenterImg from "@/assets/img/case-page/kems-center.svg";
import kemsRightImg from "@/assets/img/case-page/kems-right.svg";
import shopLeftImg from "@/assets/img/case-page/shop-left.png";
import shopRightImg from "@/assets/img/case-page/shop-right.png";
import PrismPopPhoneImg from "@/assets/img/case-page/PrismPop-phone.png";
import PrismPopMacImg from "@/assets/img/case-page/PrismPop-mac.png";
import TripalinkMacImg from "@/assets/img/case-page/Tripalink-mac.png";
import TrashausLeftImg from "@/assets/img/case-page/Trashaus-left.png";
import TrashausCenterImg from "@/assets/img/case-page/Trashaus-center.png";
import TrashausRightImg from "@/assets/img/case-page/Trashaus-right.png";
import RabbitLeftImg from "@/assets/img/case-page/rabbit-left.png";
import RabbitRightImg from "@/assets/img/case-page/rabbit-right.png";

const imgKE = <img src={iconKE} alt="KE" />
const imgUs = <img src={iconUs} alt="US" />
const imgChina = <img src={iconChina} alt="US" />
const imgAu = <img src={iconAu} alt="AU" />
const imgEgy = <img src={iconEgy} alt="EGY" />
const tagTC = <span>
  <img src={iconTC} alt="" />
  <span>腾讯云</span>
</span>
const tagMini = <span>
<img src={iconMini} alt="" />
<span>小程序</span>
</span>
const tagRubyOnRails = <span>
  <img src={iconRubyOnRails} alt="Ruby on Rails" />
  <span>Ruby on Rails</span>
</span>
const tagReact = <span>
  <img src={iconReact} alt="React" />
  <span>React</span>
</span>
const tagPostgreSQL = <span>
  <img src={iconPostgreSQL} alt="PostgreSQL" />
  <span>PostgreSQL</span>
</span>
const tagAWS = <span>
  <img src={iconAWS} alt="AWS" />
  <span>AWS</span>
</span>
const tagSwift = <span>
  <img src={iconSwift} alt="Swift" />
  <span>Swift</span>
</span>
const tagAndroid = <span>
  <img src={iconAndroid} alt="Android" />
  <span>Android</span>
</span>
const tagGolang = <span>
  <img src={iconGolang} alt="Golang" />
  <span>Golang</span>
</span>
const tagMySQL = <span>
  <img src={iconMySQL} alt="MySQL" />
  <span>MySQL</span>
</span>
const tagNode = <span>
<img src={iconMySQL} alt="Node" />
<span>Node.Js</span>
</span>
const tagFlutter = <span>
<img src={iconFlutter} alt="Flutter" />
<span>Flutter</span>
</span>

export const wapipayData = {
  imgsClassName: 'wapipay',
  subtitle: '跨境汇款平台',
  title: 'Wapi Pay',
  headerBgImg: wapipayBg,
  headerAppImg: [
    <img src={wapipayApp} alt="wapipayApp" />
  ],
  countryIcons: [imgKE],
  describe: '跨境汇款平台',
  technologyTags:[
    tagReact, tagPostgreSQL, tagAWS, tagFlutter
  ],
  backgroundContent: [
    'Wapi Pay是南非首家中非跨境支付平台，它为中非企业及个人提供24x7的极速跨境汇款服务，最快2小时完成支付。',
    '平台不仅支持多币种汇款，而且支持加密币交易转账，为用户提供方便快捷、费率低廉的跨境转账服务。'
  ],
  requiredTitle: 'Wapi Pay需要设计开发整套线上支付交易平台、移动端App以及后台管理系统。',
  requiredContent: '为了业务的扩展，结合中非两大洲的特点，Wapi Pay需要为数十万中非企业和用户提供跨境转账服务。该服务不仅支持手机端，还支持网页平台，不仅为中非用户提供了可靠的实时转账平台，还增加了中非贸易的往来，为中非经济业务合作带来了更多的便捷。',
  requiredImg: <img src={wapipayRequired} alt="required" />,
  challenges: [
    {
      title: '项目可重构性低',
      content: 'Wapi Pay接手的代码问题很多，在规定期限内团队需要对整个项目进行代码重构并解决遗留问题，及时给出新方案，开发人员的压力可谓不小。'
    },
    {
      title: '业务复杂',
      content: 'Wapi Pay涉及多个国家的跨国汇款，需求会根据当地国家汇款政策作出相应变动，同时需要保持包括手机端app版本、web版本还有兼容H5的一致性。'
    },
    {
      title: '第三方支付平台对接复杂',
      content: 'Wapi Pay需要支持肯尼亚国家的手机支付平台M-PESA，在未知环境下处理实时抛出的每种异常和错误，问题繁多而复杂。'
    },
    {
      title: '支持中英双语以及中非两地不同时差',
      content: '由于Wapi Pay涉及多国跨境汇款，需要做多语言切换以及不同时差的本地化处理，需要先开发英文版，再进行特殊处理，转换为中文版。并且，针对同一笔交易需要适配两地不同时差。'
    }
  ],
  solutionTitle: 'Wapi Pay极速实时跨境汇款服务平台',
  solutionContent: [
    '根据Wapi Pay的需求，美华柏乐为其定制设计并开发了全套支付平台，包括网页版，H5，手机移动端安卓和iOS，以及其后台管理系统。同时支持7x24小时实时极速转账汇款，包括多个非洲以及亚洲国家的业务。',
    '在后台管理系统方面，Wapi Pay针对新用户的信息审核以及转账订单的审核进行及时通知，保证用户的信息在第一时间得到更新，保证用户的每笔转账更快速通过。'
  ],
  solutionImg: <img src={wapipaySolution} alt="required" />,
  resultContent: [
    '项目总共分两个阶段进行开发，首先是Web版，H5以及手机移动端安卓和iOS的设计与开发，接着是对后台管理系统的代码进行定位修复与维护。美华柏乐团队与Wapi Pay公司的客户紧密的交流与沟通，从产品设计到各版本上线内测仅用时3个半月。后期根据Wapi Pay的客户反馈以及验收测试，及时处理问题，大大降低了老用户的重新学习软件使用的时间，以及增加了大量的新用户，为中非贸易合作提供了重要的基础桥梁。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/3.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/4.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/5.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/6.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/7.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Wapipay/8.png' alt="" />,
  ],
  showImgs: [
    <img alt="wapipayApp" src={wapipayApp}/>,
  ]
}

export const swypeData = {
  imgsClassName: 'swype',
  subtitle: '金融自动化平台',
  title: 'Swype',
  headerBgImg: swypeBg,
  headerAppImg: [
    <img src={swypeApp} alt="swypeApp" />
  ],
  countryIcons: [imgEgy],
  describe: '金融自动化平台',
  technologyTags:[
    tagReact, tagPostgreSQL, tagAWS
  ],
  backgroundContent: [
    'Swype 是一款新的财务软件，供公司管理员工的财务卡。它使企业可以轻松管理所有员工之间的卡片和财务问题。它旨在为不同类型的公司节省金钱和时间。'
  ],
  requiredTitle: '产品需要开发全套的Web端、手机端安卓和iOS应用以及负责审核的后台管理系统.',
  requiredContent: 'Swype作为埃及首创的财务类信用卡管理系统，需要整合当地银行政策以及当地第三方支付平台的特殊性，联合打造出具有企业级信用卡管理能力的系统。该系统需要支持多种不同币种的信用卡同时管理的模式，使消费纪录清晰化、透明化，帮助不同类型的企业在财务管理方面更加便捷和轻松。',
  requiredImg: <img src={swypeRequired} alt="required" />,
  challenges: [
    {
      title: '保障资金信息安全',
      content: '金融类产品由于和用户隐私、资金紧密关联，研发具有一定的特殊性。Swype采用掩码处理 、传输加密、接口补偿机制等手段，保障信息资金安全。'
    },
    {
      title: '卡片管理逻辑严谨',
      content: '该产品中的卡片不仅包含虚拟卡和实体卡，更是可以做到一人多卡、多币种卡、信用卡发放。并且用户可以在系统中对卡片进行一系列的额度管理设置。'
    },
    {
      title: '多视角监测数据',
      content: '支持个人与公司视角的随时切换，实时获取最新的统计数据，包括卡片交易流水、按照不同消费类目进行的消费行为分析、以不同时间维度进行绘制的图表等。为用户审查数据与进行下一步决策提供了有力依据。'
    },
  ],
  solutionTitle: 'Swype 企业级信用卡管理系统',
  solutionContent: [
    '针对Swype关于企业级信用卡管理的需求，美华柏乐团队在开发过程中采用掩码处理、传输加密、接口补偿机制等手段，充分保障了资金信息安全的问题。',
    '其次，Swype开发中通过个人和公司视角的随时切换，帮助公司更加方便快捷地审查数据，针对不同角色，有其定制的视角界面，方便整个公司的不同角色进行管理和审查。',
    'Swype根据市场需求，可以实时获取最新交易数据，支持不同币种、不同角色、不同视角、不同信用卡的任意切换，充分满足了一个企业级财务管理的需求。'
  ],
  solutionImg: <img src={swypeSolution} alt="required" />,
  resultContent: [
    '美华柏乐团队交付了包括Swype网页版以及手机移动端（iOS & Android）定制化的App和后台管理系统的开发。该应用支持多角色、多币种、多卡片、多视角的实时数据更新和审查，在埃及当地成为首家企业级的财务管理工具，帮助大量不同类型的企业更加便捷地管理财务问题，节省时间和金钱。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/3.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/4.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/5.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/6.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/7.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Swype/8.png' alt="" />,
  ],
  showImgs: [
    <img alt="swypeApp" src={swypeApp}/>
  ]
}

export const radpadData = {
  imgsClassName: 'radpad',
  subtitle: '移动租房一体化平台RadPad',
  title: 'RadPad',
  headerBgImg: radpadBg,
  headerAppImg: [
    <img src={radpadMac} alt="radpadMac" />
  ],
  countryIcons: [imgUs],
  describe: '房地产金融科技',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    'Radpad是洛杉矶第一、全美第二大的互联网租房平台，旨在为租客提供找房租房、信用报告、电子合同、移动支付、房屋保险等服务的一站式解决方案。',
    'Radpad曾多次被App Store评为年度最佳应用及最佳生活应用，其安卓版本多次获得谷歌Play Store官方推荐。自Radpad上线以来，共取得了1200万美元的融资。'
  ],
  requiredTitle: '公司业务扩张后，急需在管理方面获得技术支持',
  requiredContent: '由于业务发展需要，RadPad需要在房租支付、背景调查、信用报告、电子合同签署、CRM管理后台等方面获得技术支持，同时需要开发移动端应用，包括iOS APP以及安卓APP。',
  requiredImg: <img src={radpadRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    }
  ],
  solutionTitle: '一套满足所有管理需求的后台系统和面对市场的手机端应用',
  solutionContent: [
    '针对RadPad的现有技术架构与功能需求，美华柏乐为RadPad设计并开发了房租支付系统、信用报告申请系统、电子合同签署系统以及iOS应用、安卓应用和CRM后台管理系统。',
    '美华柏乐为RadPad开发的房租支付系统，对接了Yapstone、Stripe、MX等多个支付渠道，帮助RadPad完成超过2亿美金交易额，并顺利获得900万美金的A轮融资。'
  ],
  solutionImg: <img src={radpadSolution} alt="required" />,
  resultContent: [
    '美华柏乐团队负责其iOS应用、CRM系统与整体后台系统设计与开发。整套CRM管理系统帮助公司提升整体工作效率超过40%，其中客户体验部门、交易审核与监管部门以及市场宣传部门超过90%的工作依赖于该系统完成。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/RadPad/radpad-p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/RadPad/radpadp2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/RadPad/p3.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="radpad-left" src={radpadLeft}/>,
    <img alt="radpad-center" src={radpadCenter}/>,
    <img alt="radpad-right" src={radpadRight}/>
  ]
}

export const moegoData = {
  imgsClassName: 'moego',
  subtitle: '全球宠物服务Saas平台',
  title: 'Moego',
  headerBgImg: moegoBg,
  headerAppImg: [
    <img src={moegoHeaderMac} alt="moegoMac" />
  ],
  countryIcons: [imgUs, imgAu],
  describe: '宠物SaaS',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    'MoeGo是一款为全球宠物店和宠物美容师提供一站式管理服务的SaaS平台。通过整合多个第三方服务（Stripe, Square, DocuSign, MailChimp, Twilio, etc），为宠物服务商家提供移动端和PC端的全套SaaS服务，建立集服务预约、商品支付、实时沟通、预约提醒、宠物管理、员工管理于一体的完整服务体系。'
  ],
  requiredTitle: '宠物美容服务需要在预约和后台管理方面获得技术支持',
  requiredContent: '由于业务发展需要，MoeGo需要在升级改造整套PHP系统，并在管理预约、联络客户、签署服务合同、管理后台等方面获得技术支持，同时需要开发移动端应用。',
  requiredImg: <img src={moegoRequired} alt="required" />,
  challenges: [
    {
      title: '业务复杂度极高',
      content: '需要针对宠物服务行业的业务场景进行功能设计和开发，打造一个高效好用的商家SaaS平台。不仅能够满足商家日常管理需要，也需要可以增加用户粘性，提高服务复购率，因此对业务场景的理解要求极高。'
    },
    {
      title: '新老系统替换难',
      content: '需要在不影响现有业务的情况下，重构整套后台系统并增加多个新业务模块，将预约、零售、支付、聊天、订阅等功能全部整合到新的系统里，对系统架构设计形成了很大的挑战。'
    },
    {
      title: '第三方整合难',
      content: 'MoeGo新系统涉及整合多个第三方平台，包括Stripe、Square、DocuSign、MailChimp、Twilio等。需要将不同第三方平台的功能整合进新系统中，建立有效链接和合理的整体架构，对系统的稳定性与健壮性形成了挑战。'
    },
  ],
  solutionTitle: '一套针对宠物服务行业的一体化智能后台管理系统',
  solutionContent: [
    '针对MoeGo Web的商业模式和需求，美华柏乐重构整套PHP系统，并增加新业务模块，其中包括预约系统、支付系统、实时聊天、短信通知、电子合同签署系统以及iOS和安卓APP、Web应用和后台管理系统。',
    '宠物店和宠物美容师通过软件可以进行智能预约、管理订单、创建备注提醒、查看企业财务记录、评价服务、实时聊天等。其中智能预约可根据用户的日程一键生成最优化的时间安排和路线，便于移动宠物店管理上门服务。'
  ],
  solutionImg: <img src={moegoHeaderMac} alt="required" />,
  resultContent: [
    '美华柏乐团队改造PHP系统、增加新业务模块，交付了整套后台管理系统和全新SpringBoot搭建的微服务架构。技术团队使用硅谷先进的标准架构搭建该系统：React Native框架打造移动端应用，其Web应用使用React。',
    'MoeGo在软件评价网站Software Advice上获得绝大多数用户好评，评分为4.72 / 5。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/MoeGo/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/MoeGo/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/MoeGo/p3.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/MoeGo/p4.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/MoeGo/p5.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/MoeGo/p6.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="moego-left" src={moegoIphone}/>,
    <img alt="moego-right" src={moegoMac}/>,
  ]
}

export const harmayData = {
  imgsClassName: 'harmay',
  subtitle: '全球宠物服务Saas平台',
  title: 'HARMAY話梅',
  headerBgImg: harmayBg,
  headerAppImg: [
    <img src={harmayApp} alt="harmayMac" />
  ],
  countryIcons: [imgChina],
  describe: '新零售美妆',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift
  ],
  backgroundContent: [
    'HARMAY話梅是新一代美妆及生活类零售品牌, 在香港、上海、北京、成都等地均设有门店。通过简约而精致的室内设计，呼应宽敞空间的独处氛围，配合独特美学的创意元素，给予顾客完全的掌控，自由激发灵感和探索。'
  ],
  requiredTitle: '企业需要构建微信直播小程序，拓宽销售渠道并完善电商系统',
  requiredContent: '随着微信小程序逐渐成为市场趋势，Harmay需要构建全新的微信小程序平台，与其ERP后台和门店销售系统打通，进行销售模式的创新。通过建立专属直播小程序平台，进行多种类直播活动，Harmay完善了其电商体系，加大了推广及宣传力度，实现了用户群及营收的持续增长。',
  requiredImg: <img src={harmayRequired} alt="required" />,
  challenges: [
    {
      title: '多系统互联',
      content: 'Harmay話梅需要构建全新的微信小程序直播电商平台，与其既有的ERP系统和门店收银体系打通。这就要求直播电商后台系统需要与其现有的系统实现互联互通，确保商品、库存、订单等信息可以在多系统间可以实时同步，对系统设计和数据处理能力要求很高。'
    },
    {
      title: '产品创意',
      content: '小程序界面及交互需针对Harmay話梅的品牌调性进行设计，考虑品牌理念及美妆元素，精益求精，充分展现Harmay話梅的品牌特征。'
    },
    {
      title: '直播电商',
      content: '小程序主要使用场景为直播电商，涉及交互功能多，对后台系统的响应要求高。同时直播的流畅度以及独特的直播弹幕系统对平台设计的要求很高。'
    },
  ],
  solutionTitle: '专属直播电商微信小程序及直播后台管理系统',
  solutionContent: [
    '根据公司业务及销售模式需要，美华柏乐为HARMAY定制开发了专属直播电商微信小程序以及直播后台管理系统。微信直播小程序从UI设计到用户体验上充分展现了HARMAY创造美和美好生活的品牌创意，同时在功能上加入了创新娱乐元素如直播弹幕等。',
    '在后台系统方面，直播小程序与話梅现有的ERP以及商品管理系统打通，实时同步线上商品的库存、价格、折扣等信息。管理员可以通过直播后台管理系统进行直播推流、拉流、断流、商品上架/下架等操作，对直播进行全方位管理。'
  ],
  solutionImg: <img src={harmaySolution} alt="required" />,
  resultContent: [
    '项目总共分两期进行开发，美华柏乐团队与HARMAY团队紧密合作，从产品设计到小程序上线内测仅用时3个月，按照约定准时交付。',
    '微信直播小程序的开发使得HARMAY有更多空间与粉丝交流和互动。会员在离开HARMAY线下门店后，可以通过微信直播小程序观看闭店后的直播活动，进行二次消费。HARMAY通过小程序直播带动线上消费，形成流量闭环，为会员提供独一无二的线上线下体验，大大提高了客户留存率。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Harmay/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Harmay/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Harmay/p3.png' alt="" />,
  ],
  showImgs: [
    <img alt="harmay-left" src={harmayIphone1}/>,
    <img alt="harmay-right" src={harmayIphone2}/>,
  ]
}

export const cheeseData = {
  imgsClassName: 'cheese',
  subtitle: '美国留学生专属借记卡APP',
  title: 'Cheese',
  headerBgImg: cheeseBg,
  headerAppImg: [
    <img src={cheeseApp} alt="" />
  ],
  countryIcons: [imgUs],
  describe: '金融科技',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    '美华柏乐团队为Cheese增加新业务模块，交付了整套后台支付交易系统和定制化iOS App。iOS App为客户吸引用户源、积累原始用户、实现业务推广提供了及时有效的帮助。为提升用户体验感、提供便捷金融服务提供有效助力。'
  ],
  requiredTitle: '公司想要增添新业务板块来吸引用户源和实现业务推广',
  requiredContent: 'Cheese Card致力于成为美国版余额宝，为用户提供储蓄奖励，其年化利率最高可达3%。此外，Cheese Card设立了捐赠机制和返现机制，用户通过消费可为亚裔福利项目捐赠，在合作商户进行消费可获最高10%的返现。',
  requiredImg: <img src={cheeseRequired} alt="required" />,
  challenges: [
    {
      title: '交易系统复杂',
      content: 'Cheese Card的支付交易系统搭建难度大，需与银行接口打通，用户在APP上要完成账户注册、KYC以及激活信用卡的全部流程。'
    },
    {
      title: '营销功能丰富',
      content: '用户可以通过邀请好友开卡而获得存款利率提升，邀请好友越多，存款利率越高。同时用户每月可以选择任意一个消费领域，刷卡获得高额返现。'
    },
    {
      title: '交互设计要求高',
      content: 'Cheese Card专为在美亚裔设计，针对该群体的产品使用习惯进行功能的设计及优化，既要符合用户使用习惯，又要达到产品交互流畅，难度较大。'
    },
  ],
  solutionTitle: '定制化iOS APP和一站式后台支付交易系统',
  solutionContent: [
    '根据Cheese的业务模式及技术需要，美华柏乐为其开发了定制化iOS App以及整套后台支付交易系统，为客户提供了安全、高效、及时的款项往来交易管理。',
    'Cheese Card用户通过App可以完成申卡、开卡、储蓄、消费、转账等操作，并且可以实时查看消费记录及财务分析。',
    '美华柏乐团队为Cheese增加新业务模块，交付了整套后台支付交易系统和定制化iOS APP。iOS APP为客户吸引用户源、积累原始用户、实现业务推广提供了及时有效的帮助。为提升用户体验感、提供便捷金融服务提供有效助力。'
  ],
  solutionImg: <img src={cheeseSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队为Cheese增加新业务模块，交付了整套后台支付交易系统和定制化iOS App。iOS App为客户吸引用户源、积累原始用户、实现业务推广提供了及时有效的帮助。为提升用户体验感、提供便捷金融服务提供有效助力。',
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Cheese/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Cheese/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Cheese/p3.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Cheese/p4.png' alt="" />,
  ],
  showImgs: [
    <img alt="cheese-left" src={cheeseImg1}/>,
    <img alt="cheese-right" src={cheeseImg2}/>,
  ]
}

export const carezData = {
  imgsClassName: 'carez',
  subtitle: '宠物垂直电商平台',
  title: '凯尔资电商',
  headerBgImg: carezBg,
  headerAppImg: [
    <img src={carezApp} alt="harmayMac" />
  ],
  countryIcons: [imgChina],
  describe: '宠物电商',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift
  ],
  backgroundContent: [
    '凯尔资公司成立于2014年，是国际高端宠物市场的中国区总代理，该公司致力于引领宠物市场的高端消费理念。目前合作的品牌包括：拉夫威尔（Ruffwear）、雷克斯佩客（Rex Specs）、味之洁（Fizzion）、窝宝（Walking Plam）和彼果（Beco Pets）。'
  ],
  requiredTitle: '企业急需构建微信电商小程序，拓宽品牌销售渠道',
  requiredContent: '随着公司业务迅速扩张以及商业需求，凯尔资需要开拓微信电商平台，为高端宠物市场引流并增加品牌销售渠道。并通过社区互动功能提升用户粘度、扩大品牌宣传。',
  requiredImg: <img src={carezRequired} alt="required" />,
  challenges: [
    {
      title: '后台系统重构',
      content: '凯尔资原有后台系统技术较为落后，不仅代码质量低，而且系统性能瓶颈明显。因此，需要在充分理解现有系统逻辑的基础上，采用主流的SpringBoot框架重构整个后台系统。'
    },
    {
      title: '分销商模式创新',
      content: '针对渠道分销商设置特殊购买与返现规则，可以做到每个分销商享受不同的单品折扣与提现返点等多种灵活分销模式。'
    },
    {
      title: '流程优化难',
      content: '凯尔资旗下多个独家代理品牌独立运营，团队协作效率较低。如何开发一套统一的后台管理系统，方便不同团队协同办公，打通数据、共享资源，难度较大。'
    },
  ],
  solutionTitle: '宠物垂直电商小程序和整套后台管理系统',
  solutionContent: [
    '根据公司业务及销售模式需要，美华柏乐为其打造了宠物垂直电商小程序和整套后台管理系统。小程序为其实现了更流畅的用户网购流程；社区功能则为商城平台引流推广。后台系统为凯尔资提供统一的商品管理。',
    '顾客通过使用凯尔资商城小程序，可以进行网购、追踪订单、社区发帖等操作。而公司运营人员可以通过后台管理系统实时管理订单、同步线上商品信息、并为顾客提供及时的服务和帮助。',
  ],
  solutionImg: <img src={carezSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队开发并按时交付，为客求提供了强有力的技术支撑。搭建的微信电商小程序和整套后台管理为平台提升商品管理效率、增加了用户使用粘性并扩大了品牌销售渠道。',
    '小程序为其提供完整的商城系统，实现了更流畅的用户网购流程；社区功能增加互动感，为商城平台引流推广。后台与其财务系统打通，为凯尔资提供统一的商品库存、销售和折扣中心系统。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Carez/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Carez/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Carez/p3.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="carez" src={carezImg}/>,
  ]
}

export const qihuibaoData = {
  imgsClassName: 'qihuibao',
  subtitle: '智能政策匹配与自动化申报平台',
  title: '企惠宝',
  headerBgImg: qihuibaoBg,
  headerAppImg: [
    <img src={qihuibaoApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '政策申报',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagGolang
  ],
  backgroundContent: [
    '企惠宝应用人工智能与大数据、为中小微科技企业打造的智能政策匹配与自动化申报平台。该平台帮助企业进行智能推荐、一键提交表单和全流程跟踪服务，实现了更高效、精准和安全的政策扶持申请。'
  ],
  requiredTitle: '为企业提供智能化政策匹配与自动化申报服务',
  requiredContent: '通过数据挖掘与企业政策知识图谱构建，为企业提供智能化政策匹配与自动化申报服务，打通企业与政府之间的壁垒，为企业申报政府补贴提供高效便捷的途径。与传统中介相比，企惠宝平台提高了50%以上申报效率，费用低至传统中介20%。',
  requiredImg: <img src={qihuibaoRequired} alt="required" />,
  challenges: [
    {
      title: '数据挖掘难度大',
      content: '政府政策发布不确定因素多、发布渠道广。全国每年有上万条政策信息由数百个政府网站发布，这对数据采集与挖掘提出了很高的要求。'
    },
    {
      title: '政策图谱要求高',
      content: '政府政策内容相对复杂，需要通过自然语言处理技术（NLP）提取有效信息并进行创建政策知识图谱，难度较大。'
    },
    {
      title: '精准匹配困难多',
      content: '根据企业工商信息、融资信息、知识产权等信息提取企业特性，并根据政策知识图谱进行精准匹配，对算法模型的开发具有很大挑战。'
    },
  ],
  solutionTitle: '全国政策自动化匹配与申报服务平台网站',
  solutionContent: [
    '企惠宝为美华柏乐自有产品，团队完成了从产品构思、市场分析、交互设计到软件开发的全流程。该产品已与北京海淀创业园、WeWork等众创空间达成合作，为数千家中小微科技企业提供政策匹配与高新申报SaaS服务。',
    '通过数据挖掘与深度学习技术，构建了具有自主知识产权的全国政策与企业知识图谱，完成了全国政策自动化匹配与申报服务平台。通过运用数据技术，数据收集、数据分析、智能匹配，对潜在政策信息进行分析及对应匹配。为申报政府扶持的企业扩大信息量、减少流程成本。',
  ],
  solutionImg: <img src={qihuibaoSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队自主开发了Web应用和后台管理系统。做到了全国政策实时监控和采集。平台目前拥有超过8万政策扶持、300万余企业工商知识产权和投融资数据。帮助企业更高效的搜寻匹配政策，促进更积极的创业环境。',
    '该产品已与北京海淀创业园、WeWork等众创空间达成合作。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p2.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p3.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p4.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p5.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="qihuibao-mac" src={qhbMacImg}/>,
    <img alt="qihuibao-top" src={qhbTopImg}/>,
    <img alt="qihuibao-bottom" src={qhbBottomImg}/>,
  ]
}

export const glassesData = {
  imgsClassName: 'glasses',
  subtitle: '城市管理眼镜APP',
  title: '中海智能执法眼镜',
  headerBgImg: glassesBg,
  headerAppImg: [
    <img src={glassesApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '政府职能',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagMySQL, tagAndroid
  ],
  backgroundContent: [
    '中海智能眼镜与其APP是海淀区“城市大脑”的项目之一。“城市大脑”是以互联网为基础设施，基于城市资源，对其进行全局的即时分析和指挥，从而促进城市管理领域的执法工作。'
  ],
  requiredTitle: '开发智能执法眼镜第一时间直播取证功能，协助城市管理工作',
  requiredContent: '根据城市管理领域智能化、现代化的需求，城管局需开发智能执法眼镜，将人工智能引入城市执法工作，执法人员执行任务时通过佩戴智能眼镜，实现实时直播、第一时间取证的效果。佩戴期间记录的图片及视频将上传云端，方便城市执法人员及时取证和回放，实现智能执法的迭代升级。',
  requiredImg: <img src={glassesRequired} alt="required" />,
  challenges: [
    {
      title: '硬件调试困难大',
      content: '智能眼镜采用800万像素的UVC摄像头，如何通过APP实时采集UVC数据流并转换为RMTP数据流进行推流直播难度很大。'
    },
    {
      title: '直播延时低',
      content: '由于产品应用场景为城管执法现场，对直播画面质量与流畅度要求很高，同时又要达到小于2秒的直播延迟，对APP与后台直播性能要求很高。'
    },
    {
      title: '系统逻辑复杂',
      content: 'APP需要满足多部门多用户的权限管理与直播配置，方便部门领导观看一线执法人员的实时画面，并可以进行实时文字与图片交流。'
    },
  ],
  solutionTitle: '定制化Android APP和整套后台管理系统',
  solutionContent: [
    '针对其需求，美华柏乐为其定制开发了其Android App以及后台管理系统。智能眼镜连接到手机端后，进行直播、储存视频到云端，保证实时图像的真实性，以防止篡改。',
    '该应用通过协助执法人员第一视角直播，对其突发情况进行记录、分析和管理，从而实现对城市的协同指挥。做到60秒接报响应，3分钟事件报传，通过语音识别、自然语义分析等AI手段，实现突发事件处置全过程可追溯。',
  ],
  solutionImg: <img src={glassesSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队开发并按时交付APP和后台管理系统，为政府职能领域提供了强有力的技术支撑。搭建的Android APP以及后台管理系统为城市执法人员提供了视频直播、储存、数据加密、实时定位等功能。提升了监控和取证效率、促进了城市管理领域的执法工作。',
    '中海智能眼镜APP目前处于内测阶段，已在北京市海淀区多个区域进行试用，执法人员佩戴智能执法眼镜进行执法实时直播及执法记录云端存储，推进了执法工作智能化、现代化改革。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Zhonghai/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Zhonghai/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Zhonghai/p3.png' alt="" />,
  ],
  showImgs: [
    <img alt="glasses" src={glassesImg}/>,
    <img alt="glasses-app" src={glassesAppImg}/>,
  ]
}

export const kemsData = {
  imgsClassName: 'kems',
  subtitle: '黑科技健身线上线下一体化小程序及CRM管理系统',
  title: 'K-EMS Studio',
  headerBgImg: kemsBg,
  headerAppImg: [
    <img src={kemsApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '健身,电商',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagPostgreSQL
  ],
  backgroundContent: [
    'K-EMS Studio是中国专业EMS科技健身连锁品牌。全国拥有超过200家线下健身会所。K-EMS通过将源自美国宇航局NASA的EMS技术融入健身，把真正核心的健康理念带给数十万会员。'
  ],
  requiredTitle: '企业需要开发一套B端的后台管理系统和C端的小程序满足融资需求',
  requiredContent: '随着公司业务迅速扩张以及融资需要，K-EMS需要开发一整套线上信息系统。包括to C端的APP或小程序，方便会员线上买课、预约以及查看训练数据，to B端的后台管理系统、线上营销系统以及教练排课系统，方便公司进行一体化线上管理及运营。',
  requiredImg: <img src={kemsRequired} alt="required" />,
  challenges: [
    {
      title: '软硬件协作联动',
      content: '会员通过微信小程序约课后，需要在门店扫码签到，同时与教练共同扫码启动机器进行续联，所有数据实时上传至后台管理系统，方便运营人员实时跟踪。'
    },
    {
      title: '智能训练方案',
      content: '会员训练时，智能硬件实时采集用户身体数据，包括心率、体脂率、肌肉含量等，统一上传到后台系统，后台结合用户性别、年龄、体重等因素为会员制定个性化训练方案。'
    },
    {
      title: '管理难度大',
      content: 'K-EMS Studio全国线下门店超过200家，拥有数十万会员、数百位工作人员，系统数据量大，管理困难。'
    },
  ],
  solutionTitle: '一体化的技术解决方案，包含微信小程序、后台管理系统、财务系统、排课系统以及线上营销系统',
  solutionContent: [
    '针对客户需求与行业竞品分析，美华柏乐为K-EMS提供了一体化的技术解决方案。包括设计与开发微信小程序、后台管理系统、财务系统、排课系统以及线上营销系统。',
    '顾客通过使用微信小程序，可以进行购课、约课、签到等操作。在每次训练结束后可以在小程序端查看自己的训练数据，包括训练强度、消耗卡路里数、体脂率、肌肉含量等。而公司运营人员与门店教练可以通过后台管理系统实时了解课程销售情况以及进行排课、约课等操作。',
  ],
  solutionImg: <img src={kemsSolution} alt="solution" />,
  resultContent: [
    '项目总共分三期进行开发，美华柏乐每一期都按时交付，为客户的融资需求提供了强有力的技术支撑。系统如期上线后，K-EMS小程序因其丰富的功能以及流畅的用户体验，在短时间获得了大量顾客好评，同时也增加了投资人信心。',
    '最终K-EMS母公司顺利获得数千万人民币Pre-A轮融资，投后估值达到3亿人民币。全国200多家门店、数十万顾客使用该小程序进行购课、预约、锻炼等操作。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p3.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p4.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p5.png' alt="" />,
  ],
  showImgs: [
    <img alt="kems-left" src={kemsLeftImg}/>,
    <img alt="kems-center" src={kemsCenterImg}/>,
    <img alt="kems-right" src={kemsRightImg}/>,
  ]
}

export const shopshopsData = {
  imgsClassName: 'shopshops',
  subtitle: 'Shopshops哪逛',
  title: 'Shopshops哪逛',
  headerBgImg: shopshopsBg,
  headerAppImg: [
    <img src={shopshopsApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '海淘,电商',
  technologyTags:[
    tagReact, tagMini, tagAWS, tagSwift, tagPostgreSQL
  ],
  backgroundContent: [
    'ShopShops是集旅游购物指南、时尚分享社区、实体店跨境直购等功能于一体的跨境电商平台，旨在为用户提供最直接、最便捷的交互式跨境购物体验。ShopShops致力于建立境外商户与全球消费者之间的连接，让消费者摆脱代购困扰，一站直达海外精品店。通过实时策展、交互设计等技术，用户可以进行虚拟购物，获得最真实的一手信息。'
  ],
  requiredTitle: '因业务扩张，公司需要构建一整套完整的电商系统',
  requiredContent: '根据公司业务需要，ShopShops需要开发一套美国电商数据整合系统，通过API接口形式，为其国内直播APP提供美国商品价格、库存、品类等信息的实时查询功能，同时支持用户下单、锁定库存、跨境支付等操作。',
  requiredImg: <img src={shopshopsRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '整套部署在美国AWS的电商数据整合系统和直播购物APP',
  solutionContent: [
    '美华柏乐为其打造了整套部署在美国AWS的电商数据整合系统。该系统与全球超过100个合作商户数据系统进行无缝对接，实时获取商家最新商品库存以及价格等信息，并可在线完成库存锁定、下单以及跨境支付等操作。',
    '国内用户通过其直播App，可以实时观看平台主播在北美线下门店进行的直播，与主播实时互动交流、评论，并选择满意的产品下单使用支付宝或微信支付购买。',
  ],
  solutionImg: <img src={shopshopsSolution} alt="solution" />,
  resultContent: [
    '项目总共分三期进行开发，美华柏乐团队与ShopShops技术团队协同开发，每一期都按时交付，为客求提供了强有力的技术支撑。',
    '系统如期上线后。截至目前，ShopShops已完成2000万美元融资，与150多个品牌和零售商建立了合作关系；从奢侈品设计师到多品牌商店再到独立零售商与全球1000家零售商合作；为来自纽约，洛杉矶和迈阿密等城市的中国客户提供数千场直播购物活动。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Shopshops/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Shopshops/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Shopshops/p3.png' alt="" />,
  ],
  showImgs: [
    <img alt="shop-left" src={shopLeftImg}/>,
    <img alt="shop-right" src={shopRightImg}/>,
  ]
}

export const prismPopData = {
  imgsClassName: 'prismPop',
  subtitle: '美妆社区分享平台APP',
  title: 'PrismPop',
  headerBgImg: prismPopBg,
  headerAppImg: [
    <img src={prismPopApp} alt="" />
  ],
  countryIcons: [imgUs],
  describe: '美妆电商',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    'Prismpop是一家在线美妆社区分享平台，致力于帮助用户找到个性化的产品，被称为“美国版小红书”。平台汇聚了上千位欧美美妆达人的数万条短视频。通过任务分发、积分兑奖、小样赠送等营销活动，提高美妆博主的参与热情。用户可以根据兴趣和独特需求探索个性化的美容产品，分享护肤品，交流使用心得。'
  ],
  requiredTitle: '产品需要一套定制化的美妆社区互动系统',
  requiredContent: '根据公司业务需要，ShopShops需要开发一套社区互动系统，包括了iOS APP、后台API接口以及智能推荐系统。',
  requiredImg: <img src={prismPopRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '定制化iOS APP、后台API接口以及智能推荐系统',
  solutionContent: [
    '美华柏乐为其设计开发了定制化iOS APP、后台API接口以及智能推荐系统。',
    '智能推荐系统可以根据不同的内容、关键词、受众偏好等将广告展示给更有意向的用户。用户可以在IOS App平台进行好物分享、美妆测评、美妆发现、用户交流等活动。',
  ],
  solutionImg: <img src={prismPopSolution} alt="solution" />,
  resultContent: [
    '项目总共分三期进行开发。智能推荐系统为用户提供更贴合需求的内容，面向不同阶段、不同身份的群体提供针对性的推荐，提高用户体验感，唤起用户的消费需求。',
    '目前Prismpop已完成由美国红杉资本领投的数百万美元种子轮融资。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/PrismPop/p1.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/PrismPop/p2.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="PrismPop-mac" src={PrismPopMacImg}/>,
    <img alt="PrismPop-phone" src={PrismPopPhoneImg}/>,
  ]
}

export const tripalinkData = {
  imgsClassName: 'tripalink',
  subtitle: '北美多元共居社区WEB应用',
  title: 'Tripalink',
  headerBgImg: tripalinkBg,
  headerAppImg: [
    <img src={tripalinkApp} alt="" />
  ],
  countryIcons: [imgUs],
  describe: '房屋租赁',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    'Tripalink是致力于打造“Co-living”居住模式的租房平台。Tripalink与房地产开发商、经纪人、房主和物业管理公司合作，为美国的千禧一代打造专属的共享生活体验，成为最受当下美国千禧一代及青年白领欢迎的租房平台。'
  ],
  requiredTitle: '因业务扩张，公司需要一套网上看房系统和后台管理系统',
  requiredContent: '随着公司业务扩张以及技术需要，Tripalink需要开发一套网上看房系统和后台管理系统，方便用户能在网上更高效、更精准地找到合适房源并完成线上签约。',
  requiredImg: <img src={tripalinkRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '定制化线上看房、预约平台以及后台管理系统',
  solutionContent: [
    '美华柏乐根据其需求，量身打造了线上看房、预约平台以及后台管理系统。为用户提供更真实直观的房源，打消用户租房顾虑，提供更加便捷高效的线上租赁服务，为客户实现规模发展提供助力。',
  ],
  solutionImg: <img src={tripalinkSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队为Tripalink增加新业务模块，交付了整套后台支付交易系统和线上看房系统，为用户提供更真实直观的房源。',
    '目前Tripalink获得超过1800万美元融资。自2016年成立以来，Tripalink在三年时间实现房源数量从100到3000的增长，覆盖城市从洛杉矶扩张到西雅图、费城、旧金山等10大城市，服务群体也从留学生扩展到全美的青年学生及白领。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Tripalink/p1.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Tripalink/p2.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Tripalink/p3.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="Tripalink-mac" src={TripalinkMacImg}/>,
  ]
}

export const trashausData = {
  imgsClassName: 'trashaus',
  subtitle: '垃圾溯源小程序',
  title: 'Trashaus',
  headerBgImg: trashausBg,
  headerAppImg: [
    <img src={trashausApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '环保科技',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagPostgreSQL
  ],
  backgroundContent: [
    'Trashaus的企业客户可以通过SaaS系统创建、编辑多种类溯源产品，并实现“一物一码”功能。用户在购买由回收原材料制作的商品（如环保手机壳）时，通过微信扫码即可打开溯源小程序，查看产品的“前世今生”，了解环保产品的生产流程。'
  ],
  requiredTitle: '公司需要开发移动平台帮助企业处理可再生废弃物并实时追踪去向',
  requiredContent: 'Trashaus的企业客户可以通过SaaS系统创建、编辑多种类溯源产品，并实现“一物一码”功能。用户在购买由回收原材料制作的商品（如环保手机壳）时，通过微信扫码即可打开溯源小程序，查看产品的“前世今生”，了解环保产品的生产流程。',
  requiredImg: <img src={trashausRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '微信溯源小程序以及统一的SaaS后台管理系统',
  solutionContent: [
    '随着公司业务扩张以及技术需要，Trashaus需要开发微信溯源小程序以及统一的SaaS后台管理系统，帮助消费者解决包装对环境影响的顾虑，同时提高品牌的用户粘性。',
  ],
  solutionImg: <img src={trashausSolution} alt="solution" />,
  resultContent: [
    '美华柏乐交付了多个品牌小程序和统一的SaaS后台管理系统，有助于解决消费者对外卖废弃包装的环境影响产生的顾虑，在“垃圾”中创造价值，帮助品牌和消费者消费后的市场沟通联系。',
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Trashaus/P1.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Trashaus/p2.png' alt="" />,
  ],
  showImgs: [
    <img alt="Trashaus-left" src={TrashausLeftImg}/>,
    <img alt="Trashaus-center" src={TrashausCenterImg}/>,
    <img alt="Trashaus-right" src={TrashausRightImg}/>,
  ]
}

export const rabbitData = {
  imgsClassName: 'rabbit',
  subtitle: '生鲜线上零售app',
  title: 'Rabbit',
  headerBgImg: rabbitBg,
  headerAppImg: [
    <img src={rabbitApp} alt="" />
  ],
  countryIcons: [imgEgy],
  describe: '生鲜电商',
  technologyTags:[
    tagTC, tagReact,tagNode, tagAWS, tagFlutter
  ],
  backgroundContent: [
    'Rabbit是埃及版的“美团外卖”项目，主打超市生鲜20分钟配送。埃及用户可以在APP上浏览附近超市的商品，在线下单，享受优惠折扣，商家在20分钟内完成送货上门服务。让消费者足不出户即可享受在线购买和极速配送服务。'
  ],
  requiredTitle: '产品需要开发一整套电商+配送以及后台管理的APP和CRM系统',
  requiredContent: 'Rabbit希望综合“超市+配送”功能，在埃及首创实体超市与线上电商结合的商业模式。以突出经营生鲜、强化物流配送为主要特色，通过打通线上与线下，并多引流到线上，再通过店仓一体，从而实现极致的生鲜购物和物流配送体验的目标。',
  requiredImg: <img src={rabbitRequired} alt="required" />,
  challenges: [
    {
      title: '第三方程序整合复杂',
      content: 'Rabbit与Googlemap、Keycloak、Softec等多个第三方程序合作，共享并传输用户和订单数据，保证与各个程序对接无误，耗时长，工作量大。'
    },
    {
      title: '搭载多个硬件工作',
      content: 'APP集成ZEBRA手持扫码设备和EPSON热敏打印机协同完成商品拣选入库等工作，需要连接硬件，进行真机调试。'
    },
    {
      title: '产品商业模式创新',
      content: '客户采用线上APP和线下自营超市覆盖生鲜食品的一体化商业模式，利用实体门店为电商引流，是在埃及当地的创新尝试。'
    },
    {
      title: '支持英语和阿拉伯语双语版本',
      content: '整个平台支持英语和阿拉伯语切换，需要先开发英语版app，再进行特殊处理，转换为阿拉伯语版本。'
    },
  ],
  solutionTitle: 'RabbitMart和RabbitPicker，定制化 APP和CRM管理系统',
  solutionContent: [
    '针对埃及当地配送服务业务场景以及Rabbit线下自营超市商业特点，美华柏乐为其开发了完整的iOS及Android APP，RabbitMart和Picker以及后台ERP及CRM管理系统。整个平台支持英语及阿拉伯语双语言切换。',
    'RabbitMart通过技术驱动产业链升级，为当地用户提供便捷、高效的线上线下消费体验。',
    'RabbitPicker深度结合实际使用场景，最大化提升内部人员工作效率。结合管理后台，使门店经理和工作人员多方联动协作，保证工作流畅性，有效提高超市门店管理水平。',
  ],
  solutionImg: <img src={rabbitSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队交付了包括RabbitMart和RabbitPicker两款定制化iOS & Android APP和整套后台管理系统。 利用实体门店为App吸引用户源、积累客户，同时搭载硬件设备和ERP、CRM管理系统，帮助实现极致的生鲜购物和物流配送体验的目标。',
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Rabbit/rabbit-1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Rabbit/rabbit-2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Rabbit/rabbit-3.png' alt="" />,
  ],
  showImgs: [
    <img alt="Rabbit-left" src={RabbitLeftImg}/>,
    <img alt="Rabbit-right" src={RabbitRightImg}/>,
  ]
}

export const caseDatas = [swypeData, wapipayData, radpadData, moegoData, harmayData, cheeseData, carezData, qihuibaoData, glassesData, kemsData, shopshopsData, prismPopData, tripalinkData, trashausData, rabbitData]

export const homepageDatas = [rabbitData, cheeseData, carezData, qihuibaoData]