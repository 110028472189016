// 是否手机端
export const isMobile = () => {
  let WINDOW = false
  if (!window.navigator) {
    WINDOW = false
  } else {
    if (/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)) {
      WINDOW = false
    } else {
      WINDOW = true
    }
  }
  return !WINDOW
}

// 是否中文版
export const isZh = () => {
  let lsLocal = localStorage.getItem('blur-website-locale');
  if ( !!lsLocal && lsLocal ) {
    if (lsLocal === 'zh') {
      return true
    } else {
      return false
    }
    return true
  } else {
    const {lang} = getParameters();
    const validLang = ['zh', 'en'].includes(lang) ? lang : 'en'
    localStorage.setItem('blur-website-locale', validLang);
    return validLang === 'zh'
  }
  return true
}

export const openMeiqia = () => {
  try {
    window._MEIQIA('showPanel')
  } catch (error) {
    message.error('打开聊天失败，请手动打开')
  }
}

export const getParameters = (url) => {
	const href = url ?? window.location.href
	const query = href.substring(href.indexOf('?') + 1);
	const vars = query.split("&");
	const obj = {}
	for (var i = 0; i < vars.length; i++) {
			let pair = vars[i].split("=");
			obj[pair[0]] = pair[1]
	}
	return obj;
}