import React from 'react'
import QueueAnim from "rc-queue-anim";
import { OverPack } from "rc-scroll-anim";
import "./index.less";
import { useTranslation } from 'react-i18next';
import TweenOne from 'rc-tween-one';
import ButtonArrow from "@/components/ButtonArrow/index.js";
import { useHistory } from "react-router-dom";


const ProjectCases = (props) => {
	const { t, i18n } = useTranslation();
  const history = useHistory()
  return (
		<div className='projects'>
      {props.caseDatas && props.caseDatas.map((item, index) => {
        return <OverPack
          always={false}
          playScale={[0.3, 0.7]}
          key={index}
          className={`projects-cases ${props.className || ''}`}
        >
        <QueueAnim
          key="queue"
          className="projects-cases-container"
          interval={120}
          leaveReverse
          type="bottom"
          component={"div"}
        >
          <TweenOne
            animation={[{ opacity: 0, type: 'set' }, {opacity: 1, type: 'to', delay: 1000}]}
            component='div'
            key="subtitle"
            className="projects-cases-container-subtitle"
          >{t(item.subtitle)}</TweenOne>
          <TweenOne
            animation={[{ opacity: 0, type: 'set' }, {opacity: 1, type: 'to', delay: 1000}]}
            component='div'
            key="title"
            className="projects-cases-container-title"
          >{t(item.title)}</TweenOne>
          <div className='projects-cases-container-description' key="description">
            <TweenOne
              animation={[{ opacity: 0, type: 'set' }, {opacity: 1, type: 'to', delay: 500}]}
              component='span'
              className="projects-cases-container-description-line"
            ></TweenOne>
            <TweenOne
              animation={[{ opacity: 0, type: 'set' }, {opacity: 1, type: 'to', delay: 1800}]}
              component='span'
              className="projects-cases-container-description-circle"
            ></TweenOne>
            <div className='projects-cases-container-description-content'>
              <TweenOne
                animation={[{ opacity: 0, type: 'set' }, {opacity: 1, type: 'to', delay: 1500}]}
                component='div'
                className="projects-cases-container-description-content-tags"
              >
                <span>{item.countryIcons}</span>
                <span>{t(item.describe)}</span>
              </TweenOne>
              {item.backgroundContent.map((i, index) => <TweenOne
                key={index}
                animation={[{ opacity: 0, type: 'set' }, {opacity: 1, type: 'to', delay: 1500}]}
                component='div'
                className={`projects-cases-container-description-content-background projects-cases-container-description-content-background-${index}`}
              >{t(i)}</TweenOne>
              )}
              <ButtonArrow
                btnArrow = {{
                  style: {marginTop: 40},
                  onClick: () => { history.push(`/case/${item.imgsClassName}`) }
                }}
                title={t('查看详情')}
              />
            </div>
            <TweenOne
              animation={[{ opacity: 1, type: 'set' }]}
              component='div'
              className={`projects-cases-container-description-requiredImg ${props.showImgClassName || ''} ${props.showImgClassName ? (props.showImgClassName + `-${item.imgsClassName}-imgs`) : ''}`}
            >
              {props.imgName ? item[props.imgName] : item.solutionImg}
            </TweenOne>
            {/* <div
              className='projects-cases-container-description-requiredImg'
            >{item.requiredImg || item.showImgs}</div> */}
          </div>
        </QueueAnim>
      </OverPack>
      })}
    </div>
  )
}

export default ProjectCases;
