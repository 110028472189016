import React, { useState } from 'react'
import { Row, Col } from "antd";
import teamPic1 from "@/assets/img/home-page/coreTeam/1.png";
import teamPic2 from "@/assets/img/home-page/coreTeam/2.png";
import teamPic3 from "@/assets/img/home-page/coreTeam/3.png";
import teamPic4 from "@/assets/img/home-page/coreTeam/4.png";
import teamPic5 from "@/assets/img/home-page/coreTeam/5.png";
import teamPic6 from "@/assets/img/home-page/coreTeam/6.png";
import teamPic7 from "@/assets/img/home-page/coreTeam/7.png";
import teamPic8 from "@/assets/img/home-page/coreTeam/8.png";
import teamPic9 from "@/assets/img/home-page/coreTeam/9.png";
import teamPic10 from "@/assets/img/home-page/coreTeam/10.png";
import teamPic11 from "@/assets/img/home-page/coreTeam/11.png";
import teamPic12 from "@/assets/img/home-page/coreTeam/12.png";
import teamPic13 from "@/assets/img/home-page/coreTeam/13.png";
import teamPic14 from "@/assets/img/home-page/coreTeam/14.jpg";
import "./index.less";
import { useTranslation } from 'react-i18next';
const CoreTeam = ({ isMobile, pageStyle = {} }) => {
	const { t, i18n } = useTranslation();
	const teamArr = [
		{ title: 'Justin J.', subtitle: '创始人兼CEO', imgSrc: teamPic1, info: '' },
		{ title: 'Dwight S.', subtitle: '高级后端工程师', imgSrc: teamPic13, info: '' },
		{ title: 'Jeana X.', subtitle: '技术副总裁', imgSrc: teamPic4, info: '' },
		{ title: 'Nicole J.', subtitle: '运营总监兼COO', imgSrc: teamPic2, info: '' },
		{ title: 'Lance L.', subtitle: '高级后端工程师', imgSrc: teamPic3, info: '' },
		{ title: 'Eric L.', subtitle: '技术经理', imgSrc: teamPic5, info: '' },
		{ title: 'Kathy G.', subtitle: 'UI设计总监', imgSrc: teamPic6, info: '' },
		{ title: 'Alex L.', subtitle: '高级后端工程师', imgSrc: teamPic7, info: '' },
		{ title: 'Jessica J.', subtitle: '项目经理', imgSrc: teamPic8, info: '' },
		{ title: 'Frank X.', subtitle: '高级前端工程师', imgSrc: teamPic9, info: '' },
		{ title: 'Karen Z.', subtitle: 'UI设计师', imgSrc: teamPic10, info: '' },
		{ title: 'Ray P.', subtitle: '高级安卓工程师', imgSrc: teamPic11, info: '' },
		{ title: 'Xie W.', subtitle: '高级后端工程师', imgSrc: teamPic12, info: '' },
		{ title: 'Adam S.', subtitle: '高级前端工程师', imgSrc: teamPic14, info: '' },
	]

	const renderPerson = () => (
		<div className="core-team-content">
			<div className="team-list">
				{
					teamArr.map((item, index) => (
						<div
							className="team-li"
							key={`${index}`}
						>
							<img alt="" src={item.imgSrc} className="team-li-pic" />
							{
								isMobile() ? (
									<div className="intro-box-mobile">
										<div className="intro-title">{item.title}</div>
										—
										<div className="intro-subtitle intro-title">{t(item.subtitle)}</div>
									</div>
								) : (
									<div className="intro-box">
										<div className="intro-title">{item.title}</div>
										<div className="intro-subtitle intro-title">{t(item.subtitle)}</div>
										{/* <div className="intro-info">{item.info}</div> */}
									</div>
								)
							}
						</div>
					))
				}
			</div>
		</div>
	)
  return (
    <div className="core-team" style={pageStyle}>
			<Row>
				<Col xs={3} sm={3}></Col>
				<Col xs={isMobile() ? 18 : 10} sm={18}>
					<div className="core-team-title" style={{ marginBottom: isMobile() ? 50 : 80 }}>
						<div className="team-title1">OUR GROUP</div>
						<div className="team-title2">{t('核心团队')}</div>
						<div className="team-introduction">
							{t('我们的核心团队由中美资深工程师组成，具有多年硅谷产品研发经验，为您提供高质量、全方位的设计、架构、开发、测试、数据安全等服务。此外还有医疗、电商、金融、地产、直播等行业专家作为我们的合作伙伴，为您提供全程保驾护航。')}
						</div>
					</div>
				</Col>
				<Col xs={3} sm={3}></Col>
			</Row>
			{
				isMobile() ? (
					<Row>
						<Col xs={3} sm={3}></Col>
						<Col xs={18} sm={18}>
							{renderPerson()}
						</Col>
						<Col xs={3} sm={3}></Col>
					</Row>
				) : renderPerson()
			}
			{/* <div className="core-team-content">
				<div className="team-list">
					{
						teamArr.map((item, index) => (
							<div
								className="team-li"
								key={`${index}`}
							>
								<img alt="" src={item.imgSrc} className="team-li-pic" />
								<div className="intro-box">
									<div className="intro-title">{item.title}</div>
									<div className="intro-subtitle intro-title">{item.subtitle}</div>
									<div className="intro-info">{item.info}</div>
								</div>
							</div>
						))
					}
				</div>
			</div> */}
		</div>
  )
}

export default CoreTeam;
