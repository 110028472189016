/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-08-04 11:19:31
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-04 13:52:15
 */
import React, { Fragment, useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import '@/utils/i18n';
import Header from '@/layouts/header'
import { useTranslation } from 'react-i18next';
import RouteComponent from './route'
import {getParameters} from '@/utils/utils';

function App() {
  const { t, i18n } = useTranslation();
	const {lang} = getParameters();

  useEffect(()=>{
    let lsLocal = localStorage.getItem('blur-website-locale');
    if (lsLocal) {
      i18n.changeLanguage(lsLocal)
    } else {
      const validLang = ['zh', 'en'].includes(lang) ? lang : 'en'
      i18n.changeLanguage(validLang)
      localStorage.setItem('blur-website-locale', validLang);
    }
  },[])
  return (
    <Fragment>
      <HashRouter>
        <Header/>
        <Switch>
          <Route path="/" component={RouteComponent}></Route>
        </Switch>
      </HashRouter>
    </Fragment>
  );
}
export default App;