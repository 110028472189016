/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-08-04 11:19:31
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-11 10:40:40
 */
import React, { useEffect, useState } from 'react'
import { Progress } from "antd";
import process1 from "@/assets/img/home-page/process1.png";
import process2 from "@/assets/img/home-page/process2.png";
import process3 from "@/assets/img/home-page/process3.png";
import process4 from "@/assets/img/home-page/process4.png";
import process5 from "@/assets/img/home-page/process5.png";
import process6 from "@/assets/img/home-page/process6.png";
import process7 from "@/assets/img/home-page/process7.png";
import process8 from "@/assets/img/home-page/process8.png";
import QueueAnim from "rc-queue-anim";
import { OverPack } from "rc-scroll-anim";
import "./index.less";
import { useTranslation } from 'react-i18next';
const BusinessProcess = (props) => {
	const { t, i18n } = useTranslation();
	// const [isUpdate, setIsUpdate] = useState(true);
	let timer1;
	let timer2;
	const stepData = [
    { label: t('需求沟通'), icon: process1, backgroundColor: 'rgba(110, 104, 248, 0.1)', color: 'rgba(110, 104, 248, 1)' },
    { label: t('解决方案'), icon: process2, backgroundColor: 'rgba(110, 104, 248, 0.1)', color: 'rgba(110, 104, 248, 1)' },
    { label: t('项目启动'), icon: process3, backgroundColor: 'rgba(110, 104, 248, 0.1)', color: 'rgba(110, 104, 248, 1)' },
    { label: t('UI/UX设计'), icon: process4, backgroundColor: 'rgba(28, 134, 255, 0.1)', color: 'rgba(85, 120, 241, 1)' },
    { label: t('系统架构'), icon: process5, backgroundColor: 'rgba(28, 134, 255, 0.1)', color: 'rgba(85, 120, 241, 1)' },
    { label: t('产品研发'), icon: process6, backgroundColor: 'rgba(28, 134, 255, 0.1)', color: 'rgba(85, 120, 241, 1)' },
    { label: t('质量测试'), icon: process7, backgroundColor: 'rgba(239, 192, 70, 0.1)', color: 'rgba(239, 192, 70, 1)' },
    { label: t('验收发布'), icon: process8, backgroundColor: 'rgba(239, 192, 70, 0.1)', color: '#EFC046' },
  ]
	// useEffect(() => {
	// 	if (isUpdate) {
	// 		timer1 && clearTimeout(timer1);
	// 		timer1 = setTimeout(() => {
	// 			setIsUpdate(false);
	// 			timer2 && clearTimeout(timer2);
	// 			timer2 = setTimeout(() => {
	// 				setIsUpdate(true);
	// 			}, 100)
	// 		}, 10000)
	// 	}
	// }, [isUpdate])
  return (
		<OverPack style={{ minHeight: 90 }} always={true} playScale={[0.2, 0.8]}>
			<QueueAnim
				key="queue"
				className="stepWarp-box"
				interval={120}
				leaveReverse
				type="bottom"
				component={"div"}
			>
				<div className="stepWarp">
					{
						stepData.map((item, index) => (
							<div key={`${index}`} className={`stepLi ${(index +1) % 2 === 0 && 'lowerStep'}`}>
								<div className="info">
									<div className="progressBox">
										<Progress
											type="circle"
											strokeColor={item.color}
											trailColor="#fff"
											percent={(index + 1) * 12.5}
											format={() => 
												<span style={{ color: item.color, fontSize: 20 }}>
													{index + 1}
												</span>
											}
											width={40}
										/>
									</div>
									<div className="line">
										<span style={{ backgroundColor: item.color }} className="sub-circle" />
									</div>
									<div className="label">{item.label}</div>
									<div className="iconWarp" style={{ background: item.backgroundColor }}>
										<img alt="" src={item.icon} className="icon-img" />
									</div>
								</div>
							</div>
						))
					}
				</div>
				{/* <div className="stepWarp-box">
					{
						isUpdate && (
							
						)
					} 
					
				</div> */}
			</QueueAnim>

		</OverPack>
  )
}

export default BusinessProcess;
