import React, { useEffect, useState } from "react";
import { useParams } from 'react-router'
import "./index.less";
import Footer from "@/components/Footer/index.js";
import ButtonArrow from "@/components/ButtonArrow/index.js";
import * as datas from './data.js'
import { useTranslation } from 'react-i18next';
import ConcatUs from '../components/ConcatUs/index.js'
import challengesIcon from "@/assets/img/case-page/detail/codicon_pass.svg";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Image } from 'antd';
import _ from 'lodash'
import { isZh } from "@/utils/utils"

function CaseDetail() {
  const { t, i18n } = useTranslation();
  const { dataName } = useParams()
  const data = datas[`${dataName}Data`]
  const [isScreenLg, setScreenLg] = useState(true)
  const [visible, setVisible] = useState(false)
  const [currentSrc, setCurrentSrc] = useState(0)

  useEffect(() => {
    window.onresize = _.debounce(onresizeEvent, 150)
    return () => {
      window.onresize = null
    }
  }, [])

  const onresizeEvent = () => {
    // @screen-lg
    setScreenLg(window.innerWidth > 960)
  }

  console.log('data', data)
  return (
    data ? <>
      <div className="case-detail">
        {/* <div className={`case-detail-header case-detail-imgs-${data.imgsClassName}`} style={{backgroundImage: `url(${data.headerBgImg})`}}> */}
        <div className={`case-detail-header case-detail-imgs-${data.imgsClassName}`}>
          <img src={data.headerBgImg} alt='headerBg' />
          {data.headerAppImg}
        </div>
        {/* 核心技术 */}
        <div className='case-detail-technology'>
          <div className='case-detail-technology-title'>
            <span>{data.countryIcons}</span>
            <span>{t(data.describe)}</span>
          </div>
          <div className='case-detail-technology-tags'>
            <div className='case-detail-technology-tags-title'>{t('核心技术')}</div>
            <div className='case-detail-technology-tags-wrap'>{data.technologyTags}</div>
          </div>
        </div>
        {/* 项目背景 */}
        <div className='case-detail-project'>
          <div className='case-detail-project-wrap'>
            <div className='case-detail-project-wrap-title'>{t("项目背景")}</div>
            {data.backgroundContent.map((i, index) => <div key={index} className='case-detail-project-wrap-content'>{t(i)}</div>)}
          </div>
        </div>
        <div className='case-detail-demand'>
          <div className='case-detail-demand-content'>
            <div>{isZh() === true ? (<span>项目需求 </span> ) : null} PROJECT</div>
            <div className={`case-detail-demand-content-title case-detail-demand-content-title-${data.imgsClassName}`}>{t(data.requiredTitle)}</div>
            <div className='case-detail-demand-content-detail'>{t(data.requiredContent)}</div>
          </div>
          {data.requiredImg}
        </div>
        <div className={`case-detail-challenges case-detail-challenges-${data.imgsClassName}`}>
          <div className='case-detail-challenges-title'>
            <div>{isZh() === true ? (<span>挑战 </span> ) : null} CHALLENGES</div>
            <div>{t('开发产品的困难与挑战')}</div>
          </div>
          <div className={`case-detail-challenges-list case-detail-challenges-${data.imgsClassName}-list`}>
            {data.challenges.map((i, index) => <div key={index} className='case-detail-challenges-list-item'>
              <div>{t(i.title)}</div>
              <div>{t(i.content)}</div>
              <img src={challengesIcon} alt='challenges'/>
            </div>)}
          </div>
        </div>
        <div className='case-detail-demand case-detail-solution'>
          <div className='case-detail-demand-content' style={{order: 2}}>
            <div>{isZh() === true ? (<span>解决方案 </span> ) : null} SOLUTION</div>
            <div className='case-detail-demand-content-title'>{t(data.solutionTitle)}</div>
            {data.solutionContent.map((i, index) => <div
              className='case-detail-demand-content-detail'
              key={index}
            >{t(i)}</div>)}
          </div>
          {data.solutionImg}
        </div>
        <div className='case-detail-achievement'>
          <div className='case-detail-achievement-wrap'>
            <div className='case-detail-achievement-wrap-title'>{t("交付成果")}</div>
            {data.resultContent.map((i, index) => <div key={index} className='case-detail-achievement-wrap-content'>
              {t(i)}
            </div>)}
          </div>
        </div>
        <div className='case-detail-carousel'>
          <div className='case-detail-carousel-title'>{data.title}{t('产品详情图')}</div>
          <Carousel
            showArrows={!visible}
            centerMode={isScreenLg}
            centerSlidePercentage={50}
            selectedItem={1}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={!visible}
            interval={4000}
          >
            {data.carouselImgs.map((i, index) => <div
              key={index}
              className={`case-detail-carousel-item case-detail-carousel-item-${data.imgsClassName}`}
              onClick={() => {
                setVisible(true)
                setCurrentSrc(index)
              }}
            >{i}</div>)}
          </Carousel>
        </div>
        <ConcatUs />
        <div style={{display: 'none', height: 0}}>
          <Image.PreviewGroup preview={{visible, current: currentSrc, onVisibleChange: (v) => setVisible(v)}}>
            {data.carouselImgs.map((i, index) => <Image width={200} key={index} src={i?.props?.src ?? ''} />)}
          </Image.PreviewGroup>
        </div>
      </div>
      <Footer />
    </> : <>
      <div className="case-detail-empty">暂无该案例数据</div>
    </>
  );
}

export default CaseDetail;
